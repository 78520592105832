
<template>
  <section class="cta-style-three">
    <div class="thm-container text-center" v-if="getFixedDepositRates.length > 0">
      <h3>Fixed Deposit Rates</h3>
      <div class="thm-container text-left">
        <div class="table-responsive exchange-table">
          <table class="table">
            <thead>
              <tr>
                <th>TIER (GHS)</th>
                <th>30-day (%)</th>
                <th>60-day (%)</th>
                <th>91-day (%)</th>
                <th>182-day (%)</th>
                <th>365-day (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(temp, index) in getFixedDepositRates" :key="index">
                <td>{{ temp.tier }}</td>
                <td>{{ temp.day30 }}</td>
                <td>{{ temp.day60 }}</td>
                <td>{{ temp.day91 }}</td>
                <td>{{ temp.day182 }}</td>
                <td>{{ temp.day365 }}</td>
              </tr>
            </tbody>
          </table>
          <!-- /.table -->
        </div>
        <!-- /.table-responsive -->
      </div>
      <!-- /.thm-container -->
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "RatesFtContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      loaded30: [],
      loaded60: [],
      loaded91: [],
      loaded182: [],
      loaded365: [],
      fixedDepositRates: [
        {
          tier: "1k – 4.99k",
          day30: "9.75",
          day60: "10.50",
          day91: "15.00",
          day182: "15.75",
          day365: "17.00",
        },
        {
          tier: "5k – 9.99k",
          day30: "10.00",
          day60: "10.75",
          day91: "15.75",
          day182: "16.25",
          day365: "17.50",
        },
        {
          tier: "10k – 24.99k",
          day30: "10.25",
          day60: "11.00",
          day91: "16.25",
          day182: "16.75",
          day365: "18.00",
        },
        {
          tier: "25k – 49.99k",
          day30: "10.50",
          day60: "11.25",
          day91: "16.75",
          day182: "17.25",
          day365: "18.25",
        },
        {
          tier: "50k – 99.99k",
          day30: "10.75",
          day60: "11.50",
          day91: "17.25",
          day182: "17.50",
          day365: "18.75",
        },
        {
          tier: "100k – 249.99k",
          day30: "11.00",
          day60: "11.75",
          day91: "17.50",
          day182: "17.75",
          day365: "19.25",
        },
        {
          tier: "250k – 499.99k",
          day30: "11.25",
          day60: "12.00",
          day91: "17.75",
          day182: "18.25",
          day365: "19.50",
        },
        {
          tier: "500k – 999.99k",
          day30: "11.50",
          day60: "12.25",
          day91: "18.25",
          day182: "18.75",
          day365: "20.00",
        },
        {
          tier: "1m and above",
          day30: "11.75",
          day60: "12.50",
          day91: "18.75",
          day182: "19.25",
          day365: "20.25",
        },
      ],
    };
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    getFixedDepositRates() {
      var fixedDepositRates = [];
      for (var i = 0; i < this.loaded30.length; i++) {
        fixedDepositRates.push({
          tier: this.loaded30[i].tier,
          day30: this.loaded30[i].rate,
          day60: this.loaded60[i].rate,
          day91: this.loaded91[i].rate,
          day182: this.loaded182[i].rate,
          day365: this.loaded365[i].rate,
        });
      }
      return fixedDepositRates;
    },
  },

  created() {
    this.$store.dispatch("loadRates", "fd").then((data) => {
      this.loaded30 = data.filter((e) => e.days == 30);
      this.loaded60 = data.filter((e) => e.days == 60);
      this.loaded91 = data.filter((e) => e.days == 91);
      this.loaded182 = data.filter((e) => e.days == 182);
      this.loaded365 = data.filter((e) => e.days == 365);
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
