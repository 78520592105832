<template>
  <div class="footer-top">
    <div class="thm-container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="single-footer-top">
            <p>
              <i class="bitmex-icon-phone-call"></i><span>Call: </span>030 2230
              010 / 057 7669 346
            </p>
          </div>
          <!-- /.single-footer-top -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div class="single-footer-top">
            <p>
              <i class="bitmex-icon-placeholder"></i
              ><span>Address: </span>C557/3 Mango Tree Avenue Asylum Down -
              Accra, Ghana
            </p>
          </div>
          <!-- /.single-footer-top -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </div>
  <!-- /.footer-top -->

  <footer class="site-footer">
    <div class="thm-container">
      <div class="row masonary-layout" style="height: 340.8px !important">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-footer-widget">
            <div class="title">
              <h3>Quick Links</h3>
            </div>
            <!-- /.title -->
            <ul class="links-list">
              <li><a :href="`news`">Stories</a></li>
              <li><a :href="`form-download`">Forms</a></li>
              <li><a :href="`form-download`">Loan Calculator</a></li>
            </ul>
            <!-- /.links-list -->
          </div>
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->

        <!-- /.col-md-3 -->
        <!-- /.col-md-3 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <SubmitComplain />
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </footer>
  <!-- /.site-footer -->

  <div class="footer-bottom">
    <div class="thm-container clearfix">
      <div class="pull-left copy-text">
        <p>&copy; 2015 - 2021 DCI Microfinance All copy rights are reserved.</p>
      </div>
      <!-- /.pull-left copy-text -->
      <div class="social pull-right">
        <a href="https://twitter.com/dcighana" class="fa fa-twitter"></a>
        <!-- -->
        <a
          href="https://www.facebook.com/DCIMICROFINANCE/"
          class="fa fa-facebook"
        ></a>
        <a
          href="https://www.youtube.com/channel/UCJsgjGDajNa_QB5_JIuJBhQ"
          class="fa fa-youtube"
        ></a>
        <a
          href="https://www.linkedin.com/company/dci-microfinance-limited/?viewAsMember=true"
          class="fa fa-linkedin"
        ></a>
        <!--
        <a href="#" class="fa fa-youtube-play"></a
      
        <a href="#" class="fa fa-pinterest"></a> ---->
      </div>
      <!-- /.social -->
    </div>
    <!-- /.thm-container -->
  </div>
  <!-- /.footer-bottom -->

  <div class="scroll-to-top scroll-to-target" data-target="html">
    <i class="fa fa-angle-up"></i>
  </div>
</template>

<script>
import SubmitComplain from "./SubmitComplain.vue";

export default {
  name: "Footer",
  components: {
    SubmitComplain,
  },
  props: {},
  computed: {
    getCurrentPage() {
      return this.$store.getters.getCurrentPage;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
