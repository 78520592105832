<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />
  <!-- /.count-down-style-one-wrapper -->

  <section class="contact-page-wrapper sec-pad">
    <div class="thm-container">
      <h3>National Service / Intern Online Application</h3>
      <div class="row">
        <div class="col-md-12">
          <form
            action="#"
            @submit.prevent="submitApplication"
            class="contact-form row"
          >
            <div class="col-md-12">
              <input
                type="text"
                placeholder="Name"
                name="name"
                v-model="name"
                required
              />
            </div>
            <div class="col-md-12 col-sm-12">
              <select
                class="selectpicker"
                v-model="formType"
                @change="changeInApplicationType"
              >
                <option value="Internship">Internship</option>
                <option value="National Service">National Service</option>
              </select>
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-12" v-if="isIntern">
              <input
                type="text"
                placeholder="Institution"
                name="Institution"
                v-model="institution"
                required
              />
            </div>
            <!-- /.col-md-6 -->

            <div class="col-md-12" v-if="isIntern">
              <input
                type="text"
                placeholder="Programme"
                name="Programme"
                v-model="programme"
                required
              />
            </div>

            <div class="col-md-12" v-if="isNSS">
              <input
                type="text"
                placeholder="NSS Number"
                name="nssnumber"
                v-model="nssNumber"
                required
              />
            </div>

            <!-- /.col-md-6 -->
            <!-- /.col-md-6 -->
            <div class="col-md-12" v-if="isIntern">
              <div class="row changed-input">
                <div class="col-12" v-if="hasIntroductory">
                  {{ checkIntroductoryFile }}
                </div>
                <div class="col-12">
                  <label for="fileIntroductory" class="btn btn-default btn-file"
                    >Introductory Letter
                    <i class="fa fa-file" v-if="hasIntroductory"></i>
                    <i class="fa fa-folder-open" v-else></i>
                    <input
                      type="file"
                      accept="application/msword, application/pdf, image/*"
                      placeholder="Introductory Letter"
                      name="Introductory Letter"
                      class="inputfile"
                      id="fileIntroductory"
                      @change="fileInputChange"
                      style="display: none"
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            <!-- /.col-md-6 -->
            <!-- /.col-md-6 -->
            <div class="col-md-4" v-if="isNSS">
              <div class="row changed-input">
                <div class="col-12" v-if="hasCoverLetter">
                  {{ checkCoverLetterFile }}
                </div>
                <div class="col-12">
                  <label for="fileCoverLetter" class="btn btn-default btn-file"
                    >Cover Letter
                    <i class="fa fa-file" v-if="hasCoverLetter"></i>
                    <i class="fa fa-folder-open" v-else></i>
                    <input
                      style="display: none"
                      required
                      accept="application/msword, application/pdf, image/*"
                      type="file"
                      placeholder="Cover Letter"
                      class="inputfile"
                      @change="fileInputChange"
                      name="cletter"
                      id="fileCoverLetter"
                  /></label>
                </div>
              </div>
            </div>
            <!-- /.col-md-6 -->
            <!-- /.col-md-6 -->
            <div class="col-md-4" v-if="isNSS">
              <div class="row changed-input">
                <div class="col-12 selected-text" v-if="hasCV">
                  {{ checkCVFile }}
                </div>
                <div class="col-12">
                  <label for="fileCv" class="btn btn-default btn-file"
                    >Curriculum Vitae <i class="fa fa-file" v-if="hasCV"></i>
                    <i class="fa fa-folder-open" v-else></i>
                    <input
                      type="file"
                      required
                      class="inputfile"
                      accept="application/msword, application/pdf, image/*"
                      placeholder="CV "
                      name="cv"
                      id="fileCv"
                      @change="fileInputChange"
                      style="display: none"
                  /></label>
                </div>
              </div>
            </div>
            <!-- /.col-md-6 -->
            <!-- /.col-md-6 -->
            <div class="col-md-4" v-if="isNSS">
              <div class="row changed-input">
                <div class="col-12" v-if="hasPassportFile">
                  {{ checkPassportFile }}
                </div>
                <div class="col-12">
                  <label for="filePassport" class="btn btn-default btn-file">
                    Passport Image
                    <i class="fa fa-file" v-if="hasPassportFile"></i>
                    <i class="fa fa-folder-open" v-else></i>

                    <input
                      type="file"
                      placeholder="passport "
                      class="inputFile"
                      name="passport Image"
                      accept="application/msword, application/pdf, image/*"
                      id="filePassport"
                      style="display: none"
                      @change="fileInputChange"
                      required
                  /></label>
                </div>
              </div>
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-12" style="padding-top: 20px">
              <button
                class="thm-btn yellow-bg"
                type="submit"
                :disabled="loading || !checkCanSubmit"
              >
                Submit Application
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                <i class="fa fa-check-circle-o" v-if="submitted"></i>
              </button>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.contact-page-wrapper -->
  <!-- /.contact-page-wrapper -->

  <Footer />
</template>
<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import * as filestack from "filestack-js";
const client = filestack.init("ASWeaZBnDQ0u0DBpNaFkFz");
export default {
  name: "ApplyOnlineNssIntern",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
  },
  computed: {
    checkPassportFile() {
      return "Selected File : " + this.passportFile;
    },
    checkCVFile() {
      return "Selected File : " + this.cV;
    },
    checkCoverLetterFile() {
      return "Selected File : " + this.coverLetter;
    },
    checkIntroductoryFile() {
      return "Selected File : " + this.introductory;
    },
    checkCanSubmit() {
      if (this.isNSS) {
        if (
          this.nssNumber == "" ||
          this.name == "" ||
          !this.hasCoverLetter ||
          !this.hasCV ||
          !this.hasPassportFile
        ) {
          return false;
        }
      } else {
        if (
          this.institution == "" ||
          this.name == "" ||
          this.programme == "" ||
          !this.hasIntroductory
        ) {
          return false;
        }
      }
      return true;
    },
  },
  data: function () {
    return {
      name: "",
      nssNumber: "",
      institution: "",
      programme: "",
      isNSS: false,
      isIntern: true,
      loading: false,
      formType: "Internship",
      submitted: false,
      hasPassportFile: false,
      hasCV: false,
      hasCoverLetter: false,
      hasIntroductory: false,
      passportFile: "",
      cV: "",
      coverLetter: "",
      introductory: "",
      passportUrl: null,
      cvUrl: null,
      coverLetterUrl: null,
      introductoryUrl: null,
      filesUploaded: [],
    };
    //LateMonth
  },
  methods: {
    submitApplication() {
      var formData = new FormData();
      if (this.hasPassportFile) {
        this.filesUploaded.push({
          filename: "passport Image",
          url: this.passportUrl,
        });
        formData.append("passport Image", this.passportUrl);
      }
      if (this.hasCV) {
        this.filesUploaded.push({ filename: "cv file", url: this.cvUrl });
        formData.append("cv file", this.cvUrl);
      }
      if (this.hasCoverLetter) {
        this.filesUploaded.push({
          filename: "cover letter",
          url: this.coverLetterUrl,
        });
        formData.append("cover letter", this.coverLetterUrl);
      }
      if (this.hasIntroductory) {
        this.filesUploaded.push({
          filename: "introductory letter",
          url: this.introductoryUrl,
        });
        formData.append("introductory letter", this.introductoryUrl);
      }
      const safeEnvoy = axios.create({
        baseURL: `https://semrapi.onovait.com/`,
      });
      var message = "";
      if (this.isNSS) {
        message = "NSS Number : " + this.nssNumber;
      } else {
        message =
          "Programme : " +
          this.programme +
          " Institution : " +
          this.institution;
      }
      this.loading = true;
      try {
        formData.append("_email", "recruitment@diamondcapital.com.ghh");
        formData.append("_subject", "New Online NSS/Intern Application");
        formData.append("_headless", true);
        formData.append("name", this.name);
        formData.append("message", message);
        safeEnvoy
          .post("envoy", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e);
            this.submitted = true;
            this.loading = false;
            this.name = "";
          });
      } catch (error) {
        console.log({ error });
      }
    },
    changeInApplicationType() {
      if (this.formType == "Internship") {
        this.isIntern = true;
        this.isNSS = false;
      } else {
        this.isNSS = true;
        this.isIntern = false;
      }
    },
    fileInputChange(e) {
      const file = e.target.files[0];
      //console.log(URL.createObjectURL(file))
      this.loading = true;
      client.upload(file).then((res) => {
        console.log(res);
        this.loading = false;
        switch (e.target.attributes.id.nodeValue) {
          case "fileIntroductory":
            this.introductoryUrl = file;
            this.hasIntroductory = true;
            this.introductory = file.name;
            break;
          case "filePassport":
            this.passportUrl = file;
            this.hasPassportFile = true;
            this.passportFile = file.name;

            break;
          case "fileCv":
            this.cvUrl = file;
            this.hasCV = true;
            this.cV = file.name;
            break;
          case "fileCoverLetter":
            this.coverLetterUrl = file;
            this.hasCoverLetter = true;
            this.coverLetter = file.name;
            break;
          default:
            break;
        }
      });
    },
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
<style scoped>
.btn-file {
  margin-top: 10px;
}
.changed-input {
  margin-left: 0px;
}
</style>