<template>
  <section class="contact-page-wrapper blog-style-one">
    <div class="thm-container" style="margin-top: 50px">
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-8">
          <form class="contact-form row" @submit.prevent="sendEmail">
            <div class="col-md-12">
              <input
                type="hidden"
                placeholder="Savings Account"
                name="accType"
              />
              <input
                type="text"
                placeholder="Mandatory Initial Deposit"
                name="mandatoryInitialDeposit"
                required
                v-model="deposit"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Name"
                name="name"
                v-model="name"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Surname"
                name="surname"
                v-model="surname"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Title"
                name="title"
                v-model="title"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Nationality"
                name="nationality"
                v-model="nationality"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Date Of Birth"
                name="dob"
                v-model="dob"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Place Of Birth"
                required
                name="pob"
                v-model="pob"
              />
            </div>
            <div class="col-md-6">
              <input
                type="tel"
                required
                placeholder="Mobile"
                name="phone"
                v-model="phone"
              />
            </div>

            <div class="col-md-6">
              <input
                type="email"
                required
                placeholder="Email Address"
                name="email"
                v-model="email"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Residential Address"
                name="res"
                v-model="res"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Occupation"
                name="occupation"
                v-model="occupation"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Employer"
                name="employer"
                v-model="employer"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Type Of Card"
                name="cardType"
                v-model="cardType"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Place Of Issue"
                name="poi"
                v-model="poi"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="ID Number"
                name="idNumber"
                v-model="idNumber"
              />
            </div>

            <div class="col-md-12">
              <button class="thm-btn yellow-bg" type="submit" value="Send">
                Send Request
              </button>
            </div>
            <div class="col-md-12 mt-10" v-if="submitted">
              <div class="row">
                <div class="alert alert-success">
                  {{ message }}
                </div>
              </div>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "OpenSavingAccount",
  data: function () {
    return {
      submitted: false,
      message:
        "Thank you for your interest in our corporate account, we will get in touch shortly",

      deposit: "",
      name: "",
      surname: "",
      title: "",
      nationality: "",
      dob: "",
      pob: "",
      email: "",
      res: "",
      occupation: "",
      employer: "",
      cardType: "",
      phone: "",
      poi: "",
      idNumber: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  methods: {
    sendEmail() {
      try {
        console.log();
        emailjs.sendForm(
          "service_kwzkfg6",
          "template_shr9lib",
          ".contact-form"
          /*
          {
            deposit: this.deposit,
            name: this.name,
            surname: this.surname,
            title: this.title,
            nationality: this.nationality,
            dob: this.dob,
            pob: this.pob,
            email: this.email,
            res: this.res,
            occupation: this.occupation,
            employer: this.employer,
            cardType: this.cardType,
            phone: this.phone,
            poi: this.poi,
            idNumber: this.idNumber,
          },
          "user_wu0Bl51eEmH5LeWlfqbYu"
          */
        );
      } catch (error) {
        console.log({ error });
      }
      this.submitted = true;

      // Reset form field
      this.deposit = "";
      this.name = "";
      this.surname = "";
      this.title = "";
      this.nationality = "";
      this.dob = "";
      this.idNumber = "";
      this.poi = "";
      this.phone = "";
      this.cardType = "";
      this.employer = "";
      this.occupation = "";
      this.res = "";
      this.email = "";
      this.pob = "";
    },
  },
};
</script>
<style scoped>
</style>
