import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SubAdvisory from '../views/AdvisoryService.vue'
import Loans from '../views/Loans.vue'
import Investment from '../views/Investment.vue'
import Savings from '../views/Savings.vue'
import CorporateInformation from '../views/CorporateInformation.vue'
import VisionMission from '../views/VisionMission.vue'
import CeoMessage from '../views/CeoMessage.vue'
import Board from '../views/Board.vue'
import MgtProfile from '../views/MgtProfile.vue'
import SalaryAdvance from '../views/SalaryAdvance.vue'
import SavingAccount from '../views/SavingAccount.vue'
import SuperSaverAccount from '../views/SuperSaverAccount.vue'
import KidsAccount from '../views/KidsAccount.vue'
import FixedDeposit from '../views/FixedDeposit.vue'
import BusinessStartUp from '../views/BusinessStartUp.vue'
import BusinessGrowth from '../views/BusinessGrowth.vue'
import FinancialAdvice from '../views/FinancialAdvice.vue'
import Rates from '../views/Rates.vue'
import Feedback from '../views/Feedback.vue'
import Branches from '../views/Branches.vue'
import WhatWeLookFor from '../views/WhatWeLookFor.vue'
import WorkingAtDci from '../views/WorkingAtDci.vue'
import JobOpportunity from '../views/JobOpportunity.vue'
import Gallery from '../views/Gallery.vue'
import GallerySingle from '../views/GallerySingle.vue'
import News from '../views/News.vue'
import NewsSingle from '../views/NewsSingle.vue'
import AnnualReport from '../views/AnnualReport.vue'
import FormDownload from '../views/FormDownload.vue'
import RewardHonours from '../views/RewardHonours.vue'
import GraduateSpecialist from '../views/GraduateSpecialist.vue'
import InternshipNationalService from '../views/InternshipNationalService.vue'
import  InvoiceCertificateDiscount from '../views/InvoiceCertificateDiscount.vue'
import  LpoContractFinancing from '../views/LpoContractFinancing.vue'
import  PayrollLoan from '../views/PayrollLoan.vue'
import  RateCalculator from '../views/RateCalculator.vue'
import  LoanCalculator from '../views/LoanCalculator.vue'
import  OpenAccount from '../views/OpenAccount.vue'
import  OurRates from '../views/OurRates.vue'
import  PressRelease from '../views/PressRelease.vue'
import  BusinessCerificate from '../views/BusinessCerificate.vue'
import  FinancialInformation from '../views/FinancialInformation.vue'
import ApplyOnlineGraduate from '../views/ApplyOnlineGraduate'
import ApplyOnlineNssIntern from '../views/ApplyOnlineNssIntern'
import AgentRegister from '../views/AgentRegister'


const routes = [
  {
    path: '/press-release',
    name: 'PressRelease',
    component: PressRelease
  },
  {
    path: '/our-rates',
    name: 'OurRates',
    component: OurRates
  },

  {
    path: '/open-account',
    name: 'OpenAccount',
    component: OpenAccount
  },
  {
    path: '/loan-calculator',
    name: 'LoanCalculator',
    component: LoanCalculator
  },
  {
    path: '/rate-calculator',
    name: 'RateCalculator',
    component: RateCalculator
  },
  {
    path: '/quick-pay',
    name: 'PayrollLoan',
    component: PayrollLoan
  },
  {
    path: '/lpo-contract',
    name: 'LpoContractFinancing',
    component: LpoContractFinancing
  },
  {
    path: '/invoice-cert',
    name: 'InvoiceCertificateDiscount',
    component: InvoiceCertificateDiscount
  },
  {
    path: '/reward-honours',
    name: 'RewardHonours',
    component: RewardHonours
  },

  {
    path: '/graduate-specialist',
    name: 'GraduateSpecialist',
    component: GraduateSpecialist
  },

  {
    path: '/internship-national',
    name: 'InternshipNationalService',
    component: InternshipNationalService
  },

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/form-download',
    name: 'FormDownload',
    component: FormDownload
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited. sub-investment
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sub-advisory',
    name: 'SubAdvisory',
    component: SubAdvisory
  },
  {
    path: '/sub-investment',
    name: 'Investment',
    component: Investment
  },
  {
    path: '/sub-loans',
    name: 'Loans',
    component: Loans
  },
  {
    path: '/sub-savings',
    name: 'Savings',
    component: Savings
  },
  {
    path: '/corporate-information',
    name: 'CorporateInformation',
    component: CorporateInformation
  },

  {
    path: '/mission-vision-values',
    name: 'VisionMission',
    component: VisionMission
  },
  {
    path: '/ceo-message',
    name: 'CeoMessage',
    component: CeoMessage
  },
  {
    path: '/board',
    name: 'Board',
    component: Board
  },
  {
    path: '/mgt-profile',
    name: 'MgtProfile',
    component: MgtProfile
  },
  {
    path: '/salary-advance',
    name: 'SalaryAdvance',
    component: SalaryAdvance
  },
  {
    path: '/savings-account',
    name: 'SavingAccount',
    component: SavingAccount
  },
  {
    path: '/super-saver',
    name: 'SuperSaverAccount',
    component: SuperSaverAccount
  },
  {
    path: '/kid-account',
    name: 'KidsAccount',
    component: KidsAccount
  },
  {
    path: '/fixed-deposit',
    name: 'FixedDeposit',
    component: FixedDeposit
  },
  {
    path: '/business-start-up',
    name: 'BusinessStartUp',
    component: BusinessStartUp
  },
  {
    path: '/business-growth',
    name: 'BusinessGrowth',
    component: BusinessGrowth
  },
  {
    path: '/financial-advice',
    name: 'FinancialAdvice',
    component: FinancialAdvice
  },
  {
    path: '/rates',
    name: 'Rates',
    component: Rates
  },

  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },

  {
    path: '/branches',
    name: 'Branches',
    component: Branches
  },
  {
    path: '/what-we-look-for',
    name: 'WhatWeLookFor',
    component: WhatWeLookFor
  },
  {
    path: '/working-at-dci',
    name: 'WorkingAtDci',
    component: WorkingAtDci
  },
  {
    path: '/job-opportunities',
    name: 'JobOpportunity',
    component: JobOpportunity

  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/gallery-single',
    name: 'GallerySingle',
    component: GallerySingle
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },

  {
    path: '/news-single',
    name: 'NewsSingle',
    component: NewsSingle
  },

  {
    path: '/annual-report',
    name: 'AnnualReport',
    component: AnnualReport
  },
  {
    path: '/financial-information',
    name: 'FinancialInformation',
    component: FinancialInformation
  },
  {
    path: '/business-certificate',
    name: 'BusinessCertivicate',
    component: BusinessCerificate
  }
  ,
  {
    path: '/apply-now-graduate',
    name: 'ApplyOnlineGraduate',
    component: ApplyOnlineGraduate
  }
  ,
  {
    path: '/apply-now-other',
    name: 'ApplyOnlineNssIntern',
    component: ApplyOnlineNssIntern
  }
  ,
  {
    path: '/agent-register',
    name: 'AgentRegister',
    component: AgentRegister
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
