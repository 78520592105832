<template>
  <section class="contact-page-wrapper blog-style-one">
    <div class="thm-container" style="margin-top: 50px">
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-8">
          <form class="contact-form row" @submit.prevent="sendEmail">
            <div class="col-md-12">
              <input
                type="hidden"
                placeholder="Super Saver Account"
                name="accType"
              />
              <input
                type="text"
                placeholder="Mandatory Initial Deposit"
                name="deposit"
                v-model="deposit"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Name"
                name="name"
                v-model="name"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Surname"
                name="surname"
                v-model="surname"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Title"
                name="title"
                v-model="title"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Nationality"
                name="nationality"
                v-model="nationality"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Date Of Birth"
                name="dob"
                v-model="dob"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Place Of Birth"
                name="pob"
                v-model="pob"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                placeholder="Mobile"
                name="phone"
                v-model="phone"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                v-model="email"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Residential Address"
                name="res"
                v-model="res"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Phyiscal Address"
                name="physical"
                v-model="physical"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Office Address"
                name="office"
                v-model="office"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Occupation"
                name="occupation"
                v-model="occupation"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Employer"
                name="employer"
                v-model="employer"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Type Of Card"
                name="cardType"
                v-model="cardType"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Place Of Issue"
                name="poi"
                v-model="poi"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Monthly Contibution"
                name="monthlyContribution"
                v-model="monthlyContribution"
              />
            </div>

            <div class="col-md-12">
              <button class="thm-btn yellow-bg" type="submit" value="Send">
                Send Request
              </button>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "OpenSuperSaveFormContent",
  data: function () {
    return {
      deposit: "",
      name: "",
      surname: "",
      title: "",
      nationality: "",
      dob: "",
      pob: "",
      email: "",
      res: "",
      physical: "",
      office: "",
      occupation: "",
      employer: "",
      cardType: "",
      phone: "",
      poi: "",
      monthlyContribution: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  methods: {
    sendEmail() {
      try {
        console.log();
        emailjs.sendForm(
          "service_kwzkfg6",
          "template_shr9lib",
          ".contact-form"
          /*
          {
            deposit:this.deposit,
            name: this.name,
            itf: this.itf,
            dob: this.dob,
            pob: this.pob,
            email: this.email,
            res: this.res,
            physical: this.physical,
            office: this.office,
            occupation:this.occupation,
            employer: this.employer,
            cardType: this.cardType,
            poi: this.poi,
            minorDOB: this.minorDOB,
            minorPOB: this.minorPOB,
            minorTypeCard: this.minorTypeCard,
            minorPOI: this.minorPOI,
            source: this.source,
            phone: this.phone,
          },
          "user_wu0Bl51eEmH5LeWlfqbYu"
          */
        );
      } catch (error) {
        console.log({ error });
      }
      this.submitted = true;
      setTimeout(function () {
        window.location.reload();
      }, 1000);

      // Reset form field
      this.name = "";
      this.email = "";
      this.phone = "";
    },
  },
};
</script>
<style scoped>
</style>
