<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container" v-if="newsItems.length > 0">
      <div class="sec-title text-center">
        <!--
        <span>updates from blog</span>
        -->
        <h3>Stories</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div
          class="col-md-3 col-sm-6 col-xs-12"
          v-for="(newsItem, index) in newsItems"
          :key="index"
        >
          <div class="single-blog-style-one">
            <div class="img-box">
              <img
                :src="newsItem.cover"
                :alt="newsItem.cover"
              />
              <a :href="`/news-single?newsId=${newsItem.id}`" class="read-more"
                >+</a
              >
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"><i class="fa fa-user"></i> {{ newsItem.author }}</a>
                <a href="#"
                  ><i class="fa fa-calendar"></i> {{ new Date(newsItem.date).toDateString() }}</a>
              </div>
              <!-- /.meta-info -->
              <a :href="`/news-single?newsId=${index}`"
                ><h3>
                  {{ newsItem.title }}
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "LatestNews",
  data: function () {
    return {
      newsList: [],
    };
  },
  props: {
    msg: String,
  },
  computed: {
    newsItems() {
      //var list = this.$store.getters.getNewsList;
      var fileteresNews = this.newsList.filter((e) => {
        return e.type == "Press Release";
      });
      console.log("newsItems", fileteresNews);
      return fileteresNews.slice(-3);
      //return this.$store.getters.getLatestList;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    getImageBase() {
      return this.$store.getters.getImageBase;
    },
  },
  created() {
    this.$store.dispatch("loadallArticles").then((data) => {
      this.newsList = data;
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
