<template>
  <section class="count-down-style-one-wrapper">
    <div class="thm-container">
      <div class="row">
        <router-link to="#" >
          <div
            @click="setSubMenu('super-saver')"
            class="col-md-3 col-sm-6 col-xs-12 sec-small"
            :class="{ active: getCurrentSubPage == 'super-saver' }"
          >
            <div class="single-feature-style-one">
              <a href="#" style="text-align: center"><h3>Super Saver Account</h3></a>
            </div>
            <!-- /.single-feature-style-one -->
          </div>
        </router-link>

        <!-- /.col-md-4 -->
        <router-link to="#" >
          <div
            @click="setSubMenu('child-plus')"
            class="col-md-3 col-sm-6 col-xs-12 sec-small"
            :class="{ active: getCurrentSubPage == 'child-plus' }"
          >
            <div class="single-feature-style-one">
              <a href="#" style="text-align: center"><h3>Child Plus Account</h3></a>
            </div>
            <!-- /.single-feature-style-one -->
          </div>
        </router-link>

        <!-- /.col-md-4 -->
        <router-link to="#" >
          <div
            @click="setSubMenu('savings')"
            class="col-md-3 col-sm-6 col-xs-12 sec-small"
            :class="{ active: getCurrentSubPage == 'savings' }"
          >
            <div class="single-feature-style-one">
              <a href="#" style="text-align: center"><h3>Savings Account</h3></a>
            </div>
            <!-- /.single-feature-style-one -->
          </div>
        </router-link>

        <!-- /.col-md-4 -->
        <router-link to="#">
          <div
            @click="setSubMenu('corporate')"
            class="col-md-3 col-sm-6 col-xs-12 sec-small"
            :class="{ active: getCurrentSubPage == 'corporate' }"
          >
            <div class="single-feature-style-one">
              <a href="#" style="text-align: center"
                ><h3>Corporate Account</h3></a
              >
            </div>
            <!-- /.single-feature-style-one -->
          </div>
        </router-link>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "Subheader",
  props: {
    msg: String,
  },
  methods:{
    setSubMenu(page){
          this.$store.commit("setCurrentSubPage", page);
    }
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>

.count-down-style-one-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  opacity: 0.95;
}
.count-down-style-one-wrapper {
  position: relative;
  background: #a62062 /*url(../img/background1.jpg)*/ center center no-repeat fixed;
  background-size: cover;
}

</style>
