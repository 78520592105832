<template>
  <!-- /.preloader -->
  <Header />
  <!-- /.count-down-style-one-wrapper -->
  <section class="contact-page-wrapper sec-pad">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-8">
          <form action="inc/sendemail.php" class="contact-form row">
            <div class="col-md-4">
              <input type="text" placeholder="Name" name="name" />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-4">
              <input type="text" placeholder="Email Address" name="email" />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-4">
              <input type="text" placeholder="Phone" name="phone" />
            </div>
            <!-- /.col-md-6 -->

            <div class="col-md-12">
              <textarea
                name="message"
                placeholder="Type message here"
              ></textarea>
              <button class="thm-btn yellow-bg" type="submit">
                Send Message
              </button>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->
        <div class="col-md-4" style="color: #fff">
          <div class="contact-sidebar">
            <div class="single-contact-info">
              <div class="title">
                <h3>Address</h3>
              </div>
              <!-- /.title -->
              <p>
                C557/3 Mango Tree Avenue,
                <br />
                P.O.Box GP 167,
                <br />
                Asylum Down - Accra,
                <br />
                Ghana
              </p>
            </div>
            <!-- /.single-contact-info -->
            <div class="single-contact-info">
              <div class="title">
                <h3>Phone</h3>
              </div>
              <!-- /.title -->
              <p>
                Local: 0302230010 <br />
                Mobile: 0577669346
              </p>
            </div>
            <!-- /.single-contact-info -->

            <div class="single-contact-info">
              <div class="title">
                <h3>Email</h3>
              </div>
              <!-- /.title -->
              <p>info@diamondcapital.com.gh</p>
            </div>
            <!-- /.single-contact-info -->
            <div class="single-contact-info social-widget">
              <div class="title">
                <h3>Follow</h3>
              </div>
              <!-- /.title -->
              <div class="social">
                <a href="https://twitter.com/dcighana" class="fa fa-twitter"></a
                ><!--
                            --><a
                  href="https://www.facebook.com/DCIMICROFINANCE/"
                  class="fa fa-facebook"
                ></a
                ><!--
                            -->
                <!-- -->
                <a
                  href="https://www.youtube.com/channel/UCJsgjGDajNa_QB5_JIuJBhQ"
                  class="fa fa-youtube"
                ></a>
                <a
                  href="https://www.linkedin.com/company/dci-microfinance-limited/?viewAsMember=true"
                  class="fa fa-linkedin"
                ></a>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.single-contact-info -->
          </div>
          <!-- /.contact-sidebar -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.contact-page-wrapper -->
  <!-- /.contact-page-wrapper -->

  <Footer />
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Feedback",
  components: {
    Header,

    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Contact");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
