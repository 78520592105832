<template>
<!--
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  -->
  <router-view/>
</template>

<script>
export default {
  mounted() {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0
  }
}
</script>