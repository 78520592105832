<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="annualreport.jpg"/>
  <!-- /.count-down-style-one-wrapper -->
  <FinancialStatementContent />

  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FinancialStatementContent from "@/components/FinancialStatementContent.vue";

export default {
  name: "BusinessCerificate",  
  components: {
    InnerHeaderContent,
    Header,
    Footer,
    FinancialStatementContent,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "BusineessCertificate");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
