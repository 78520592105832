
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Background</h3>
      <p>
        DCI Microfinance Limited was incorporated in November 2011 and provisionally licensed by the Bank of Ghana in April 2012. DCI Microfinance Limited obtained a final license in October 2012 to conduct business as a Deposit Taking Microfinance Company. The Company has its Head Office at Asylum Down with branches operating across Accra.
      </p>
      <p>
        The management of the company consists of persons with over 20 years combined experience obtained at all levels in the Banking industry in Ghana and abroad.

      </p>
      <p>
        In view of this background, our approach to services is driven largely by our ability to obtain a clear understanding of specific needs of Small and Medium Scale Enterprises and provide financial services beneficial to their needs.
      </p>
      <p>
        We develop knowledge about our client's business, thus making our support valued by its relevance and insight. Our familiarity with local business, culture, politics and legal climate provides us an edge in the range of services we offer.
      </p>
      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "InvestmentContent",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
</style>
