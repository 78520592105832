<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="boardmembersneww.jpg"/>
  <!-- /.count-down-style-one-wrapper -->
  <BoardContent />

  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BoardContent from "@/components/BoardContent.vue";

export default {
  name: "Board",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
    BoardContent,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
