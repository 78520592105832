<template>
  <section class="btc-calculator sec-pad">
    <div class="thm-container" v-show="fixedRate != 0">
      <div class="sec-title text-center black">
        <span>Loan</span>
        <h3>Calculator</h3>
        <p></p>
      </div>
      <!-- /.sec-title -->
      <form action="#" class="btc-calc-form row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Enter Loan Amount (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                v-model="principal"
                placeholder="Enter principal amount (GHS)"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">Select Loan Period</div>
            <div class="col-md-12 col-sm-12">
              <select class="selectpicker" v-model="period">
                <option value="3">3 months</option>
                <option value="6">6 months</option>
                <option value="12">12 months</option>
                <option value="18">18 months</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Total Amount To Pay (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                disabled
                placeholder="Total Amount To Pay"
                v-model="returns"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
      </form>
      <!-- /.btc-calc-form -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.btc-calculator -->
</template>

<script>
export default {
  name: "LoanCalculatorContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      principal: "0",
      period: 3,
            fixedRate: 0,

      fixedDepositRates: [
        {
          rate: "8.375",
        },
        {
          rate: "6.375",
        },
        {
          rate: "4.625",
        },
        {
          rate: "4.240",
        },
      ],
    };
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    returns() {
      let temp = this.principal;
      var rate = 0;

      var principal = parseFloat(temp.replace(",", ""));
      /*
      if (this.period == 3) rate = parseFloat(this.fixedDepositRates[0].rate);

      if (this.period == 6) rate = parseFloat(this.fixedDepositRates[1].rate);

      if (this.period == 12) rate = parseFloat(this.fixedDepositRates[2].rate);

      if (this.period == 18) rate = parseFloat(this.fixedDepositRates[3].rate);
      */
      rate = parseFloat(this.fixedRate);

      console.log(rate);
      console.log(principal);
      if (isNaN(rate) || isNaN(principal)) return 0.0;

      if (principal == 0 || principal < 1 || rate == 0) return 0.0;
      rate = rate / 100;
      const time = (this.period * 30) / 365;
      const res = principal * rate * time;
      const maturity = res + principal;
      console.log(typeof maturity);
      //console.log(maturity)
      if (typeof maturity === "number") return maturity.toFixed(2);
      return 0.0;
    },
  },
  created() {
    this.$store.dispatch("loadCalcRate", "loan").then((data) => {
      console.log(data);
      this.fixedRate = data.rate;
      this.period = 24;
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
