<template>
  <section class="testimonials-style-one sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center">
        <span>what are clients' say</span>
        <h3>Testimonials</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="testimonials-style-one-carousel owl-theme owl-carousel">
        <div
          class="item"
          v-for="(testimonial, index) in testimonialList"
          :key="index"
        >
          <div class="single-testimonials-style-one">
            <p>
              {{ testimonial.message }}
            </p>
            <span>{{ testimonial.name }}</span>
            <!-- <img src="img/testi-1-1.png" alt="{{ testimonial.name }}" /> -->
          </div>
          <!-- /.single-testimonials-style-one -->
        </div>

        <!-- /.item -->
      </div>
      <!-- /.testimonials-style-one-carousel -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.testimonials-style-one -->
</template>

<script>
export default {
  name: "Testimonial",
  props: {},
  data: function () {
    return {
      testimonialList: [],
    };
  },
  computed: {
    getCurrentPage() {
      return this.$store.getters.getCurrentPage;
    },
  },
  created() {
    this.$store.dispatch("loadTestimonials").then((data) => {
      console.log(data);
      this.testimonialList = data;
      //this.loading = false;
    });
  },
};
//
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
