<template>
  <section class="btc-calculator sec-pad">
    <div class="thm-container" v-show="fixedRate != 0">
      <div class="sec-title text-center black">
        <span>Our Super Saver</span>
        <h3>Calculator</h3>
        <p></p>
      </div>
      <!-- /.sec-title -->
      <form action="#" class="btc-calc-form row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Enter Monthly Cont. (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                v-model="principal"
                placeholder="Enter monthly contribution (GHS)"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">Enter Period (Yrs)</div>
            <div class="col-md-12 col-sm-12">
              <input
                type="number"
                v-model="period"
                placeholder="Enter period (Years)"
              />
            </div>
          </div>
        </div>

        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Select Investment Time
            </div>
            <div class="col-md-12 col-sm-12">
              <select class="selectpicker" v-model="paymentOption">
                <option value="EarlyMonth">Early Month</option>
                <option value="LateMonth">Late Month</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Amount At Maturity (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                disabled
                placeholder="Amount At Maturity"
                v-model="returns"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
      </form>
      <!-- /.btc-calc-form -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.btc-calculator -->
</template>

<script>
export default {
  name: "SuperSaverCalculator",
  props: {
    msg: String,
  },
  data: function () {
    return {
      principal: "0",
      period: 0,
      fixedRate: 0,
      paymentOption: "EarlyMonth",
    };
    //LateMonth
  },

  created() {
    this.$store.dispatch("loadCalcRate", "saver").then((data) => {
      console.log(data);
      this.fixedRate = data.rate;
      this.period = 24;
      //this.loading = false;
    });
  },
  computed: {
    returns() {
      let temp = this.principal;
      var rate = this.fixedRate;
      rate = rate / 100;
      if (isNaN(rate) || isNaN(temp)) return 0.0;
      var rateDiv = rate / 12;
      var rateDivPlus = 1 + rateDiv;
      var rateTimes = this.period * 12;
      var rateDivPlusPow = Math.pow(rateDivPlus, rateTimes);
      var divPrincipal = temp / rateDiv;
      var bracketMinus = rateDivPlusPow - 1;
      var amt = 0.0;
      var _amt = bracketMinus * divPrincipal;
      if (this.paymentOption == "LateMonth") {
        amt = _amt * rateDivPlus;
      } else {
        amt = _amt;
      }
      return amt.toFixed(2);
    },
  },
};
</script>
<style scoped>
</style>
