<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container">
      <div class="sec-title text-center">
        <h3>Business Certificate</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div class="col-md-3 col-sm-6 col-xs-12"  @click="popupImage('img/operate.png')">
          <div class="single-blog-style-one">
            <div class="img-box" style="max-height: 320px">
              <img src="img/operate.png" alt="Awesome Image" />
              <a
                href="#"
                @click="popupImage('img/operate.png')"
                class="read-more"
                >+</a
              >
            </div>
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <div
          class="col-md-3 col-sm-6 col-xs-12"
          @click="popupImage('img/inco.png')"
        >
          <div class="single-blog-style-one">
            <div class="img-box" style="max-height: 320px">
              <img src="img/inco.png" alt="Awesome Image" />
              <a href="#" @click="popupImage('img/inco.png')" class="read-more"
                >+</a
              >
            </div>
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <div
          class="col-md-3 col-sm-6 col-xs-12"
          @click="popupImage('img/commence.png')"
        >
          <div class="single-blog-style-one">
            <div class="img-box" style="max-height: 320px">
              <img src="img/commence.png" alt="Awesome Image" />
              <a
                href="#"
                @click="popupImage('img/commence.png')"
                class="read-more"
                >+</a
              >
            </div>
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
    <MyModal
      v-model="isShow.value"
      :close="closeModal"
      :closeClickDimmed="false"
      :closeKeyCode="true"
    >
      <div class="modal">
        <div class="row mt-10" @click="closeModal">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <div class="text-box" @click="closeModal">
              <div class="exchange-btn-box exchange-btn-box-20 text-right">
                <i class="fa fa-times fa-2x"></i>
              </div>
            </div>
            <img :src="getUrl" style="width: 80%; height: 100%" alt="" />
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </MyModal>
  </section>
</template>

<script>
import "vue-universal-modal/dist/index.css";
import VueUniversalModal from "vue-universal-modal";

export default {
  name: "BusinessCertificateContent",
  props: {
    msg: String,
  },
  data() {
    return {
      isShow: {
        value: false,
      },
      currentUrl: "",
    };
  },
  components: {
    VueUniversalModal,
  },
  methods: {
    showModal() {
      this.isShow.value = true;
    },

    closeModal() {
      this.isShow.value = false;
    },
    popupImage(url) {
      this.showModal();
      //console.log(url);
      this.currentUrl = url;
    },
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    getUrl() {
      return this.currentUrl;
    },
  },
};
</script>
<style>
.modal {
  display: block !important;
}
</style>
