<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container">
      <div class="sec-title text-center">
        <h3>Financial Statement</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">

        <div class="col-md-3 col-sm-6 col-xs-12" v-for="(statement, index) in getFinancialStatements" :key="index">
          <div class="single-blog-style-one">
            <div class="img-box">
              <img :src="statement.image_path" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <!-- /.meta-info -->
              <div class="exchange-btn-box exchange-btn-box-20 text-left">
                <a
                  :href="statement.file_path"
                  class="thm-btn thm-btn-style-two"
                  download=""
                  ><i class="fa fa-file-text-o"></i>Download</a
                >
              </div>
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "BusinessCertificateContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      financialStatements: [],
    };
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    getFinancialStatements() {
      return this.financialStatements;
    },
  },

  created() {
    this.$store.dispatch("loadFinancialStatement").then((data) => {
      console.log(data);
      this.financialStatements = [...data];
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
