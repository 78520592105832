<template>
  <section class="contact-page-wrapper blog-style-one">
    <div class="thm-container" style="margin-top: 50px">
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-8">
          <form class="contact-form row" @submit.prevent="sendEmail">
            <div class="col-md-12">
              <input
                required
                type="text"
                placeholder="Name Of Company"
                name="nameCompany"
                v-model="nameCompany"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                required
                type="text"
                placeholder="Registration Number"
                name="regNumber"
                v-model="regNumber"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Country Of Incorporation"
                name="countryIncorporation:"
                v-model="countryIncorporation"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Date Of Incorporation"
                name="dateIncorporation"
                v-model="dateIncorporation"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Registration Office Address"
                name="regOfficeAddress"
                v-model="regOfficeAddress"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Business Address"
                name="busAddress"
                v-model="busAddress"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Mailing / Correspondence Address"
                name="mailingAddress"
                v-model="mailingAddress"
              />
            </div>
            <div class="col-md-6">
              <input
                type="tel"
                required
                placeholder="Corporate Telephone No."
                name="corporateTelephone"
                v-model="corporateTelephone"
              />
            </div>

            <div class="col-md-6">
              <input
                required
                placeholder="Email Address"
                type="email"
                name="email"
                v-model="email"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Nature Of Business"
                name="natureBusiness"
                v-model="natureBusiness"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Sector (Private/Public)"
                name="sector"
                v-model="sector"
              />
            </div>

            <div class="col-md-12">
              <button class="thm-btn yellow-bg" type="submit" value="Send">
                Send Request
              </button>
            </div>
            <div class="col-md-12 mt-10" v-if="submitted">
              <div class="row">
                <div class="alert alert-success">
                  {{ message }}
                </div>
              </div>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "OpenCorporateAccount",
  data: function () {
    return {
      submitted: false,
      message:
        "Thank you for your interest in our corporate account, we will get in touch shortly",
      nameCompany: "",
      regNumber: "",
      countryIncorporation: "",
      dateIncorporation: "",
      regOfficeAddress: "",
      busAddress: "",
      mailingAddress: "",
      corporateTelephone: "",
      email: "",
      natureBusiness: "",
      sector: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  methods: {
    sendEmail() {
      try {
        console.log();
        emailjs.send(
          "service_kwzkfg6",
          "template_alzjakd",
          {
            nameCompany: this.nameCompany,
            regNumber: this.regNumber,
            countryIncorporation: this.countryIncorporation,
            dateIncorporation: this.dateIncorporation,
            regOfficeAddress: this.regOfficeAddress,
            busAddress: this.busAddress,
            mailingAddress: this.mailingAddress,
            corporateTelephone: this.corporateTelephone,
            email: this.email,
            natureBusiness: this.natureBusiness,
            sector: this.sector,
          },
          "user_wu0Bl51eEmH5LeWlfqbYu"
        );
      } catch (error) {
        console.log({ error });
      }
      this.submitted = true;
      // Reset form field
      this.nameCompany = "";
      this.countryIncorporation = "";
      this.regNumber = "";
      this.dateIncorporation = "";
      this.regOfficeAddress = "";
      this.busAddress = "";
      this.corporateTelephone = "";
      this.email = "";
      this.natureBusiness = "";
      this.sector = "";
    },
  },
};
</script>
<style scoped>
</style>
