<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="ceosmessage.jpg"/>
  <!-- /.count-down-style-one-wrapper -->
  <CeoMessageContent />

  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CeoMessageContent from "@/components/CeoMessageContent.vue";

export default {
  name: "CorporateInformation",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
    CeoMessageContent,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
