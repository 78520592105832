
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Super Saver</h3>
      <p>
        Interest bearing account targeted at the lower-middle class, open an
        account with a minimum account balance of GHS 200.00
      </p>
      <h5>Features:</h5>
      <ul class="list-items">
        <li>
          Attractive interest rate 
        </li>
        <li>Monthly compounding interest rate</li>
        <li>Maintain a minimum balance to earn the proposed interest.</li>
        <li>Account can secure a loan at the prevailing interest rate</li>
      </ul>
      <h5>Requirements for Opening a Diamond Super Saver Account:</h5>
      <ul class="list-items">
        <li>A duly completed DCI account opening forms.</li>
        <li>Two passport size pictures.</li>
        <li>Copy of a valid national ID card.</li>
        <li>
          Copy of a valid residence permit in the case of a foreign national.
        </li>
        <li>
          Proof of residence (Utility bill/Tenancy agreement / GPS Address)
        </li>
        <li>DCI KYC form should be duly completed.</li>
        <li>Payment of the mandatory initial deposit of GHS200.00.</li>
      </ul>
      <!-- /.list-items -->
      <button class="thm-btn yellow-bg" v-on:click="openFixedDeposit()">
        Open An Account
      </button>
    </div>
    <!-- /.thm-container -->
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
  </section>
  <SuperSaverCalculator />
  <!-- /.cta-style-three -->
</template>

<script>
import SuperSaverCalculator from "./SuperSaverCalculator.vue";
import YouTube from "vue3-youtube";

export default {
  name: "SuperSaverContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    SuperSaverCalculator,
    YouTube,
  },
  methods: {
    openFixedDeposit() {
      this.$store.commit("setCurrentSubPage", "super-saver");
      this.$router.replace("open-account");
    },
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Super Saver";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
