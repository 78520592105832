<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg"/>
  
  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>What we look for</h3>
      <p>
        At DCI Microfinance Limited we seek qualified people with various levels of experience and educational background from graduate to experienced professionals to join us on our corporate journey. You should have;
      </p>
      <p>
        <strong>
        Education</strong> – A Bachelor’s Degree or better
        <br />
<strong>
        Skills</strong> – numeracy, aptitude, problem-solving, language, etc.
        <br />
<strong>
        Knowledge</strong> – financial, business appreciation, expert in a specific area
        <br />
<strong>
        Competencies</strong> – acquired in relevant technical areas where prior
        experience is essential to effective delivery
        <br />
<strong>
        Statutory and national regulatory requirements</strong> – Completion of
        compulsory National Service
        <br />

        <strong>Attitude</strong> – Can do, solution creation, teamwork, upholding the Brand,
        Volunteerism
      </p>
      <p>
        We encourage you to apply, if you share our values, have an interest in
        banking, have demonstrated strong academic performance and professional
        drive and meet the criteria as stated above.
      </p>
      <p>
        <strong>In addition you must: </strong>
        <br />
        <ul>
          <li>Have what it takes to grow a DCI Microfinance career</li>
          <li>Have a can do attitude</li>
          <li>Be committed to achieving consistent result</li>
          <li>Constantly improve</li>
          <li>Develop yourself</li>
        </ul>
      </p>

      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "WhatWeLookFor",
  components: {
    InnerHeaderContent,
    Header,

    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
