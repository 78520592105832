<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="corporateinformationnew2.jpg"/>
  <!-- /.count-down-style-one-wrapper -->
  <CorporateInformationContent />

  <Footer />
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CorporateInformationContent from "@/components/CorporateInformationContent.vue";
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";

export default {
  name: "CorporateInformation",
  components: {
    InnerHeaderContent,
    Header,    
    Footer,
    CorporateInformationContent,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
