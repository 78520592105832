<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container" v-if="images.length > 0">
      <div class="sec-title text-center">
        <h3>Albums</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div
          class="col-md-3 col-sm-6 col-xs-12"
          v-for="(image, index) in images"
          :key="index"
        >
          <div class="single-blog-style-one">
            <div class="img-box" @click="goTo(image.id)">
              <img :src="image.src" :alt="image.description" />
              <a :href="`gallery-single?imageId=${image.id}`" class="read-more"
                >+</a
              >
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <!-- /.meta-info -->
              <a :href="`gallery-single?imageId=${image.id}`"
                ><h3>
                  {{ image.description }}
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "GallerySingleContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      theatrical: "",
      loadedImages: [],
      index: null,
    };
  },
  methods: {
    goTo(index) {
      window.location.href = `gallery-single?imageId=${index}`;
      //this.$router.push({ path: `/gallery-single`, query: { imageId: index } });
    },
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    images() {
      return this.loadedImages;
    },
  },
  created() {
    this.$store.dispatch("loadAllAlbums").then((data) => {
      console.log(data);
      this.images.splice(0);
      for (var i = 0; i < data.length; i++) {
        this.images.push({
          id: data[i].id,
          src: data[i].cover,
          description: data[i].name,
          date: data[i].date,
        });
      }
      //this.loading = false;
    });
  },
};
</script>
