<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Diamond Savings Account</h3>
      <p>An Interest bearing account for your daily savings.</p>
      <h5>Features:</h5>
      <ul class="list-items">
        <li>Initial Deposit requirement of GHS 50.00</li>
        <li>Attractive interest rate of 5%</li>
        <li>Account can secure a loan at the prevailing interest rate.</li>
      </ul>
      <h5>Requirements for Opening a Diamond Savings Account:</h5>
      <ul class="list-items">
        <li>A duly completed DCI account opening forms.</li>
        <li>One passport size pictures.</li>
        <li>Copy of a valid national ID card.</li>
        <li>
          Proof of residence (Utility bill / Tenancy agreement / GPS Address).
        </li>
        <li>Payment of the mandatory initial deposit of GHS 50.00</li>
        <li>
          Copy of a valid residence permit in the case of a foreign national.
        </li>
      </ul>
      <!-- /.list-items -->
      <button class="thm-btn yellow-bg" v-on:click="openFixedDeposit()">
        Open An Account
      </button>
    </div>
    <!-- /.thm-container -->
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";

export default {
  name: "SavingAccountContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    YouTube,
  },
  methods: {
    openFixedDeposit() {
      this.$store.commit("setCurrentSubPage", "savings");
      this.$router.replace("open-account");
    },
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Diamond Savings";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
