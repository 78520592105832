
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Fixed Deposit</h3>
      <p>
        Fixed Deposit is an investment vehicle where funds or deposits are
        locked in for a specific fixed period at fixed interest rate.
      </p>
      <p>
        Rates are benchmarked with a premium of 1%-5% on prevailing 91-day
        Treasury bill rates. Rates are also influenced by the amount and tenor.
      </p>
      <p>
        Guaranteed liquidity of investment. <br />
        Early liquidation without penal charges. 24 hour notification before
        early liquidation Tenor ranges from 91 days - 365 days
      </p>
      <!--
      <h5>Features:</h5>
      <ul class="list-items">
        <li>Competitive interest rate (refer to weekly call rates)</li>
        <li>Various tenors(30 days and beyond)</li>
        <li>Guaranteed liquidity of investment</li>
        <li>48 hours notification before early liquidation</li>
      </ul>
      -->
      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
    <div class="thm-container text-right" style="margin-top: 20px">
      <YouTube
        src="https://www.youtube.com/watch?v=r-0HipoG17Y"
        @ready="onReady"
        ref="youtube"
      />
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";

export default {
  name: "InvestmentContent",
  props: {
    msg: String,
  },
  components: {
    YouTube,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
</style>
