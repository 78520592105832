<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />
  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Internship &amp; National Service</h3>
      <p>
        Our internship programmes and national service create opportunities for
        you to achieve your professional and personal aspirations.
      </p>
      <h5>Requirements:</h5>
      <ul class="list-items">
        <li>
          <h5>Internship -</h5>
          <ul class="list-items">
            <li>
              Submit an Internship/Attachment letter or from his/her institution
              online.
            </li>
            <li>Submit CV Online</li>
          </ul>
        </li>
        <li>
          <h5>National Service -</h5>
          <ul class="list-items">
            <li>
              Must have successfully completed a first degree in a reputable
              higher institution within or outside Ghana.
            </li>
            <li>Must be a Ghanaian citizen.</li>
            <li>
              Submit National Service Number (eg. NSS093487) and CV online.
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        <a
        class="thm-btn yellow-bg"
          :href="'apply-now-other'"
          >Apply Online</a
        >
      </h5>

      <!--
      <p>
        This means providing inspiring and effective leadership, open lines of
        communication, excellent learning and development opportunities, and
        competitive reward and benefits, and a culture of performance where good
        performance and success is celebrated.
      </p>
      -->

      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "InternshipNationalService",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
