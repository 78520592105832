<template>
  <section class="contact-page-wrapper blog-style-one">
    <div class="thm-container" style="margin-top: 50px">
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-8">
          <form class="contact-form row" @submit.prevent="sendEmail">
            <div class="col-md-12">
              <input
                type="hidden"
                placeholder="Child Plus Account"
                name="accType"
              />
              <input
                type="text"
                required
                placeholder="Mandatory Initial Deposit"
                name="deposit"
                v-model="deposit"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-12">
              <input
                required
                type="text"
                placeholder="Name"
                name="name"
                v-model="name"
              />
            </div>
            <!-- /.col-md-6 -->
            <div class="col-md-12">
              <input
                type="text"
                required
                placeholder="ITF"
                name="itf"
                v-model="itf"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Date Of Birth"
                name="dob"
                v-model="dob"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Place Of Birth"
                name="pob"
                v-model="pob"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Mobile"
                name="phone"
                v-model="phone"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Email Address"
                name="email"
                v-model="email"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Residential Address"
                name="res"
                v-model="res"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Phyiscal Address"
                name="physical"
                v-model="physical"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Office Address"
                name="office"
                v-model="office"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Occupation"
                name="occupation"
                v-model="occupation"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Employer"
                name="employer"
                v-model="employer"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Type Of Card"
                name="cardType"
                v-model="cardType"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Place Of Issue"
                name="poi"
                v-model="poi"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Source Of Funds"
                name="source"
                v-model="source"
              />
            </div>
            <h5>Minor's Detail</h5>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Date Of Birth"
                name="minorDOB"
                v-model="minorDOB"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Place Of Birth"
                name="minorPOB"
                v-model="minorPOB"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Type Of Card"
                name="minorTypeCard"
                v-model="minorTypeCard"
              />
            </div>

            <div class="col-md-6">
              <input
                type="text"
                required
                placeholder="Place Of Issue"
                name="minorPOI"
                v-model="minorPOI"
              />
            </div>

            <div class="col-md-12">
              <button class="thm-btn yellow-bg" type="submit" value="Send">
                Send Request
              </button>
            </div>
            <div class="col-md-12 mt-10" v-if="submitted">
              <div class="row">
                <div class="alert alert-success">
                  {{ message }}
                </div>
              </div>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "OpenChildPlusAccount",
  data: function () {
    return {
      submitted: false,
      message:
        "Thank you for your interest in our corporate account, we will get in touch shortly",

      deposit: "",
      name: "",
      itf: "",
      dob: "",
      pob: "",
      email: "",
      res: "",
      physical: "",
      office: "",
      occupation: "",
      employer: "",
      cardType: "",
      poi: "",
      minorDOB: "",
      minorPOB: "",
      minorTypeCard: "",
      minorPOI: "",
      source: "",
      phone: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  methods: {
    sendEmail() {
      try {
        console.log();
        emailjs.sendForm(
          "service_kwzkfg6",
          "template_shr9lib",
          ".contact-form"
          /*
          {
            deposit:this.deposit,
            name: this.name,
            itf: this.itf,
            dob: this.dob,
            pob: this.pob,
            email: this.email,
            res: this.res,
            physical: this.physical,
            office: this.office,
            occupation:this.occupation,
            employer: this.employer,
            cardType: this.cardType,
            poi: this.poi,
            minorDOB: this.minorDOB,
            minorPOB: this.minorPOB,
            minorTypeCard: this.minorTypeCard,
            minorPOI: this.minorPOI,
            source: this.source,
            phone: this.phone,
          },
          "user_wu0Bl51eEmH5LeWlfqbYu"
          */
        );
      } catch (error) {
        console.log({ error });
      }
      this.submitted = true;

      // Reset form field
      this.deposit = "";
      this.name = "";
      this.itf = "";
      this.dob = "";
      this.pob = "";
      this.email = "";
      this.res = "";
      this.physical = "";
      this.office = "";
      this.occupation = "";
      this.employer = "";
      this.cardType = "";
      this.poi = "";
      this.minorDOB = "";
      this.minorPOB = "";
      this.minorTypeCard = "";
      this.minorPOI = "";
      this.source = "";
      this.phone = "";
    },
  },
};
</script>
<style scoped>
</style>
