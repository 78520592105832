<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />
  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Graduates &amp; Specialists</h3>
      <p>
        We seek graduates and specialists with various levels of experience to
        join our great team
      </p>
      <h5>Requirements:</h5>
      <ul class="list-items">
        <li>
          <h5>Internship -</h5>
          <ul class="list-items">
            <li>
              Must have successfully completed their first degree in a reputable
              higher institution within or outside Ghana.
            </li>
            <li>
              Must be a Ghanaian or have the necessary work permits, if not a
              Ghanaian citizen.
            </li>
            <li>Must be 25 years or below.</li>
            <li>Submit CV online</li>
          </ul>
        </li>
        <li>
          <h5>Specialist -</h5>
          <ul class="list-items">
            <li>Submit CV online.</li>
            <li>Have at least 2 years of professional working experience.</li>
          </ul>
        </li>
      </ul>

      <h5><a class="thm-btn yellow-bg" :href="'apply-now-graduate'">Apply Online</a></h5>

      <!--
      <p>
        This means providing inspiring and effective leadership, open lines of
        communication, excellent learning and development opportunities, and
        competitive reward and benefits, and a culture of performance where good
        performance and success is celebrated.
      </p>
      -->

      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "GraduateSpecialist",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
