<template>
   <section class="fun-fact-style-one dark-bg sec-pad" style="background-image: url(/img/dcistrip.jpg); padding: 40px 0px;">
    <div class="thm-container">
      
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>

</template>

<script>
export default {
  name: "Count",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },

};
</script>
<style scoped>
</style>
