
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Invoice &amp; Certificate Discounting</h3>
      <p>
        Invoice and certificate discounting is a short-term working capital
        granted to finance debtors that allows you to improve your business’
        cash flow. Invoice and certificate discounting gives immediate value to
        customers invoices before maturity. The facility is available to clients
        who trade in credit terms. Invoices to be discounted must be from
        reputable organisations and selected government entities. Up to 70%
        value of the invoice can be discounted.
      </p>

      <h5>Loan Requirements</h5>
      <ul class="list-items">
        <li>Application letter</li>
        <li>
          Board Resolution (not needed when the company is a sole
          proprietorship)
        </li>
        <li>Copy of National ID cards of proposed signatures.</li>
        <li>Copy of invoice or certificate</li>
        <li>Bank statements for the past 6 months</li>
        <li>Company profile</li>
        <li>
          Acceptable collateral (cars or houses depending on the amount been
          applied for)
        </li>
        <li>Inspection of company premises</li>
        <li>
          Any other document that may be required by DCI Microfinance Limited
        </li>
      </ul>
      <!-- /.list-items -->
      <a class="thm-btn yellow-bg" :href="'form-download'"> Download Forms </a>
    </div>
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";

export default {
  name: "InvoiceCertificateDiscountContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    YouTube,
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Certificate and Invoice Discounting";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
