<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="openaccount.jpg" />
  <Subheader />
  <OpenChildPlusAccount v-if="(getCurrentSubPage == 'child-plus')" />
  <OpenSuperSaveFormContent  v-if="(getCurrentSubPage == 'super-saver')"/>
  <OpenSavingAccount  v-if="(getCurrentSubPage == 'savings')"/>
  <OpenCorporateAccount  v-if="(getCurrentSubPage == 'corporate')"/>

  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import OpenChildPlusAccount from "@/components/OpenChildPlusAccount.vue";
import Subheader from "@/components/Subheader.vue";
import OpenSuperSaveFormContent from "@/components/OpenSuperSaveFormContent.vue";
import OpenSavingAccount from "@/components/OpenSavingAccount.vue";
import OpenCorporateAccount from "@/components/OpenCorporateAccount.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import emailjs from "emailjs-com";

export default {
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  name: "OpenAccount",
  components: {
    InnerHeaderContent,
    Header,
    Subheader,
    OpenChildPlusAccount,
    OpenSavingAccount,
    Footer,
    OpenSuperSaveFormContent,
    OpenCorporateAccount,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
    };
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Contact");
    if(this.$store.getters.getCurrentSubPage == null)
    this.$store.commit("setCurrentSubPage", "super-saver");
  },
  methods: {
    sendEmail() {
      try {
        console.log();
        emailjs.send(
          "service_kwzkfg6",
          "template_alzjakd",
          {
            from_name: this.name,
            email: this.email,
            message: `Client Name: ${this.name} \n Client Email: ${this.email} \n Client Phone: ${this.phone}`,
          },
          "user_wu0Bl51eEmH5LeWlfqbYu"
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.phone = "";
    },
  },
};
</script>
