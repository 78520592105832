<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container" v-if="newsItems.length > 0">
      <div class="sec-title text-center">
        <h3>Press Release</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div
          class="col-md-3 col-sm-6 col-xs-12"
          v-for="(newsItem, index) in newsItems"
          :key="index"
        >
          <div class="single-blog-style-one">
            <div class="img-box">
              <img
                :src="newsItem.cover"
                :alt="newsItem.title"
                @click="window.location.href = `/news-single?newsId=${newsItem.id}`"
              />
              <a :href="`/news-single?newsId=${newsItem.id}`" class="read-more"
                >+</a
              >
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"
                  ><i class="fa fa-user"></i> by {{ newsItem.author }}</a
                >
                <a href="#"
                  ><i class="fa fa-calendar"></i>
                  {{ new Date(newsItem.date).toDateString() }}</a
                >
              </div>
              <!-- /.meta-info -->
              <a :href="`/news-single?newsId=${newsItem.id}`"
                ><h3>
                  {{ newsItem.title }}
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "PressReleaseContent",
  data: function () {
    return {
      newsList: [],
    };
  },
  props: {
    msg: String,
  },
  computed: {
    newsItems() {
      return this.newsList;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store.dispatch("loadallArticles").then((data) => {
      console.log(data)
      this.newsList = data.filter((e) => {
        return e.type === "Press Release";
      });
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
