
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Our Mission</h3>
      <p>
        Breaking boundaries in the provision of financial services where customer satisfaction and the culture of excellence reign supreme.

      </p>
      <h3>Our Vision</h3>
      <p>
        To be the premier financial services provider in the eye of our customers, employees, shareholders and all other stakeholders.
      </p>
      <h3>Our Core Values and Corporate Culture</h3>
      <p>
        The “Diamond Way” represents the brand image and culture of the company and incorporates all the tenets of good corporate governance, innovation, efficiency, transparency, attention to detail and humanity revolving around the principle of putting God first. <br/>
        The emphasis on God is the central thread that holds the company together and provides the guiding principles, vision, mission and strategic focus of the company and its relationship with clients, partners, investors, regulators and other stakeholders.

      </p>

      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "InvestmentContent",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
</style>
