<template>
<section class="cta-style-three">
    <div class="thm-container text-center">
        <h3>Free Advice to help you grow your Business</h3>
        <p>
          We assist you with Free business advisory services to help you grow your business.
        </p>
        <a :href="'feedback'" class="thm-btn">Contact Us</a>
    </div><!-- /.thm-container -->
</section><!-- /.cta-style-three -->

</template>

<script>
export default {
  name: "AdvisoryContent",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },

};
</script>
<style scoped>
</style>
