
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>DCI Salary/Allowance Advance Requirements</h3>
      <ul class="list-items">
        <li>Introductory letter from employers</li>
        <li>Bank statement of six months</li>
        <li>Copy of ID (personal and work)</li>
        <li>Guarantor required and picture of guarantor</li>
        <li>50% of salary for the number of months requested</li>
        <li>Working Capital Loan</li>
      </ul>
      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
    <div class="thm-container text-right" style="margin-top: 20px">
      <YouTube
        src="https://www.youtube.com/watch?v=r-0HipoG17Y"
        @ready="onReady"
        ref="youtube"
      />
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";

export default {
  name: "SalaryAdvanceContent",
  props: {
    msg: String,
  },
  components: {
    YouTube,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
</style>
