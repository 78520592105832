<template>
  <section class="team-style-one sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center">
        <span>Meet our team</span>
        <h3>Management Profile</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/01.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Mr. Selasie Woanyah</h3>
              <span>Managing Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 1">
                  Selasie Woanyah, co-founder and Managing Director of DCI
                  Microfinance since its establishment in 2012, brings over 15
                  years of banking experience to... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(1)"
                  ></i>
                </p>
                <p v-show="currentSelected == 1">
                  Selasie Woanyah, co-founder and Managing Director of DCI
                  Microfinance since its establishment in 2012, brings over 15
                  years of banking experience to the organization. With a
                  background in banking roles at Zenith Bank Ghana Ltd,
                  including Relationship Manager, Branch Manager, and leadership
                  positions in specialized units, he has demonstrated expertise
                  in diverse sectors. Before his banking career, Selasie gained
                  valuable experience in construction with China State Hualong
                  Construction and The Consortium consultancy firm. He holds a
                  degree in Building Technology from KNUST and is a qualified
                  lawyer, having earned LLB and LLM degrees from the University
                  of Ghana. His multifaceted skill set and extensive industry
                  knowledge contribute significantly to DCI Microfinance's
                  success and strategic direction. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/02.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Mathew Lawer Teye</h3>
              <span>Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 2">
                  Mathew Lawer Teye, co-founder and Executive Director
                  overseeing Marketing and Business Development at DCI
                  Microfinance, brings over 17 years of banking and finance
                  experience... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(2)"
                  ></i>
                </p>
                <p v-show="currentSelected == 2">
                  Mathew Lawer Teye, co-founder and Executive Director
                  overseeing Marketing and Business Development at DCI
                  Microfinance, brings over 17 years of banking and finance
                  experience from England and Ghana. His tenure at Zenith Bank
                  Ghana Ltd, notably in the Construction and Real Estate
                  financing unit, equipped him with expertise as a Relationship
                  Manager and Unit Head. Prior to Zenith Bank, he served at the
                  Royal Bank of Scotland (RBS) in Corporate Banking and gained
                  valuable experience in facilities management at Atkins,
                  Faithful and Gould in England. Mr. Teye holds an MSc in
                  Finance from the University of Bedfordshire and a BSc in
                  Administration from the University of Ghana. His extensive
                  background in both banking and real estate sectors, coupled
                  with his academic qualifications, positions him as a key
                  driver of DCI Microfinance's marketing strategies and business
                  development initiatives. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/03.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Mr Samuel Yallow</h3>
              <span>Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 3">
                  Samuel Yallow, a seasoned banker with over 15 years in the
                  financial services sector, co-founded DCI Microfinance after a
                  successful tenure at Zenith Bank ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(3)"
                  ></i>
                </p>
                <p v-show="currentSelected == 3">
                  Samuel Yallow, a seasoned banker with over 15 years in the
                  financial services sector, co-founded DCI Microfinance after a
                  successful tenure at Zenith Bank. During his six-year tenure,
                  he played integral roles in the Treasury Operations and
                  Relationship Management units, where he contributed
                  significantly to operational policy enhancements and customer
                  satisfaction initiatives. His diverse experience provided a
                  holistic understanding of banking operations and emphasized
                  the synergy between operational and marketing functions for
                  optimal customer service. Mr. Yallow holds a Bachelor’s degree
                  in Business Administration with a Finance specialization, an
                  MBA in Finance from the University of Ghana Business School
                  and an MA in Corporate and Commercial Law also from the
                  University of Ghana. His expertise and dedication make him a
                  valuable asset in driving DCI Microfinance's growth and
                  ensuring client-centric operations. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
      </div>
      <div class="row">
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/austin.jpg" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Kwesi Austin</h3>
              <span>Head, Legal &amp; AMLRO </span>
              <div class="text-justify">
                <p v-show="currentSelected != 8">
                  Kwesi Austin, a legal practitioner, serves as the Company
                  Secretary and Head of the Legal and Compliance Department at
                  DCI Microfinance Ltd ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(8)"
                  ></i>
                </p>
                <p v-show="currentSelected == 8">
                  Kwesi Austin, a legal practitioner, serves as the Company
                  Secretary and Head of the Legal and Compliance Department at
                  DCI Microfinance Ltd. Additionally, he is an Associate at
                  Amartefio & Co, Ayawaso Chambers, where he has garnered
                  extensive practical experience since 1999, specializing in
                  commercial, company law, and litigation. He holds a Bachelor
                  of Arts Degree in Economics from the University of Ghana and
                  an LLB from the University of London. Mr. Austin's exceptional
                  academic performance earned him the prestigious John Mensah
                  Sarbah Award as the overall best graduating lawyer of the
                  Ghana School of Law in 2012, following his admission to the
                  Ghana Bar in the same year. His multifaceted expertise and
                  dedication to legal excellence make him an invaluable asset to
                  the organization. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/04.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Christopher Korblah Agyemang</h3>
              <span>Group Head</span>
              <div class="text-justify">
                <p v-show="currentSelected != 4">
                  Christopher Korblah Agyeman is a fully qualified consummate
                  accounting and finance professional with close to fifteen (15)
                  years working experience in the field... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(4)"
                  ></i>
                </p>
                <p v-show="currentSelected == 4">
                  Christopher Korblah Agyeman is a fully qualified consummate
                  accounting and finance professional with close to fifteen (15)
                  years working experience in the field of accounting and
                  auditing. He cut his teeth in accounting by working as an
                  account officer at Sign Africa Ltd where he rose to the
                  position of Accountant in 2007.He moved to Baker, Tilly, Andah
                  & Andah where he climbed the ranks quickly from the position
                  of an Audit Trainee to Supervising Audit Senior within a space
                  of two (2) years a clear testament to his mettle. Christopher
                  is a member of the Institute of Chartered Accountants Ghana
                  and a member of the Chartered Institute of Taxation Ghana.
                  Christopher has won many academic and professional laurels
                  including the prestigious Bentsi-Enchill Award for the overall
                  best candidate in Company and Partnership Law in the Part Two
                  of the November, 2008 Institute of Chartered Accountants,
                  Ghana examinations and the overall best candidate in Part One
                  of the May 2007, Institute of Chartered Accountants
                  examinations. Christopher enjoys reading, travelling and
                  meeting new people. He also holds a Degree in Business
                  Commerce from the University of Cape Coast. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/05.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>James Narh Tokoli</h3>
              <span>Divisional Head, Risk</span>
              <div class="text-justify">
                <p v-show="currentSelected != 5">
                  James Narh Tokoli is a seasoned Risk Analysis and Mitigation
                  professional with a diverse skill set honed through various
                  roles such as Head of Credit ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(5)"
                  ></i>
                </p>
                <p v-show="currentSelected == 5">
                  James Narh Tokoli is a seasoned Risk Analysis and Mitigation
                  professional with a diverse skill set honed through various
                  roles such as Head of Credit, Operations, Internal Audit,
                  Branch Management, and Accounting. With a strong background in
                  relationship management and versatile management skills, he
                  excels in leadership positions. As Divisional Head for Risk,
                  overseeing Internal Control, Compliance, and Enterprise Risk
                  at DCI Microfinance Ltd, his leadership prowess is evident in
                  his ability to train teams and focus on risk management,
                  credit analysis, monitoring, and recovery. James holds a
                  Bachelors ' degree in Business Administration with an
                  Accounting option from Central University, Accra, and an MBA
                  with a Finance option, James graduated from Central University
                  College of Ghana with a BSc in Finance and Accounting. He is
                  also a part qualified candidate of Association of Certified
                  Chartered Accountant (ACCA-UK). James is passionate about
                  swimming and the search for inner spiritual harmony. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>

              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
      </div>

      <div class="row">
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/06.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Vincentia Naa Kweikor Malm-Issaka</h3>
              <span>Divisional Head, Corporate Banking & Marketing</span>
              <div class="text-justify">
                <p v-show="currentSelected != 6">
                  Vincentia Naa Kweikor Malm is a driven professional with a
                  diverse background in business development, corporate account
                  management ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(6)"
                  ></i>
                </p>
                <p v-show="currentSelected == 6">
                  Vincentia Naa Kweikor Malm is a driven professional with a
                  diverse background in business development, corporate account
                  management, and customer care. With strong communication
                  skills and a commitment to integrity, she excels in achieving
                  objectives and maintaining high standards. A creative team
                  player with excellent leadership qualities, Vincentia thrives
                  in dynamic environments and embraces change. Holding an MBA in
                  Marketing from the University of Ghana Business School and a
                  B.A. in Information Studies from the University of Ghana, she
                  demonstrates a multidisciplinary approach to problem-solving.
                  As Divisional Head at DCI Microfinance Ltd, she leads Customer
                  Experience, Corporate Affairs, and Sales Operations, driving
                  strategic planning, performance reporting, and relationship
                  management. Vincentia's achievements include receiving awards
                  for Best Marketing Team. Her proactive approach and versatile
                  skill set make her a valuable asset in any professional
                  setting. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>

              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/Akosua_Abodwo.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Akosua Abodwo Semanhyia</h3>
              <span>Divisional Head, SME Banking</span>
              <div class="text-justify">
                <p v-show="currentSelected != 7">
                  Akosua Abodwo Semanhyia is a seasoned banking and finance
                  professional renowned for her strategic vision and dynamic
                  leadership ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(7)"
                  ></i>
                </p>
                <p v-show="currentSelected == 7">
                  Akosua Abodwo Semanhyia is a seasoned banking and finance
                  professional renowned for her strategic vision and dynamic
                  leadership. With a strong background at DCI Microfinance Ltd,
                  she has excelled as both Branch Head and Divisional Head,
                  overseeing multiple branches with clarity and purpose. Akosua
                  is adept at team building, development, and motivation,
                  driving consistent success through collaborative efforts. Her
                  focus on excellence is evident in her strategic planning,
                  budgeting, and marketing initiatives, ensuring the overall
                  prosperity of the branches under her supervision. Holding an
                  Executive MBA from KNUST and a Bachelor of Arts in Social
                  Science from the same institution, Akosua's academic
                  achievements complement her practical expertise. She is
                  recognized for her strategic leadership in finance and her
                  dedication to exceptional customer service. With a proven
                  ability to lead teams, devise strategic plans, and manage
                  portfolios, Akosua is a valuable asset in the banking and
                  finance sector. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>

              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/07.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Reginald Grantson Neequaye</h3>
              <span>Divisional Head, IT, Admin &amp; Digitalization </span>
              <div class="text-justify">
                <p v-show="currentSelected != 10">
                  Reginald Grantson Neequaye is an accomplished IT professional
                  with a wealth of experience in managing ICT systems ...
                  &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(10)"
                  ></i>
                </p>
                <p v-show="currentSelected == 10">
                  Reginald Grantson Neequaye is an accomplished IT professional
                  with a wealth of experience in managing ICT systems. Holding a
                  Master's degree in Cyber Security and Digital Forensic from
                  KNUST and a Bachelor's degree in Information Technology from
                  Methodist University Ghana, he demonstrates expertise in
                  overseeing seamless IT operations. In his previous role as
                  Head of IT & Admin for eleven years, Reginald effectively
                  managed IT infrastructure, supervised staff, and aligned
                  organizational policies. As Divisional Head of IT, Admin, and
                  Digitalization, his responsibilities include fleet management,
                  administration, and budget management. Reginald excels in
                  planning, organizing, and evaluating IT and electronic data
                  operations, with skills in system administration, disaster
                  recovery, and information security. Beyond his professional
                  pursuits, he enjoys video games and reading, showcasing his
                  commitment to continuous learning and staying updated on
                  system and network engineering. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <div class="row">
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/mgt/DorcasSackey.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Elizabeth Dorcas Sackey</h3>
              <span>Head, Human Resources</span>
              <div class="text-justify">
                <p v-show="currentSelected != 9">
                  Elizabeth Dorcas Sackey is a Chartered Human Resources
                  Practitioner with over a decade of experience in Human
                  Resource Management ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(9)"
                  ></i>
                </p>
                <p v-show="currentSelected == 9">
                  Elizabeth Dorcas Sackey is a Chartered Human Resources
                  Practitioner with over a decade of experience in Human
                  Resource Management. As Head of Human Resource, she excels in
                  developing and implementing HR policies, recruitment,
                  training, talent management, and employee communications. Her
                  strategic approach ensures sustainable succession planning and
                  talent development, contributing to organizational success.
                  Elizabeth actively pursues professional development, serving
                  in committee roles like Secretary of the Performance
                  Assessment and Promotions Committee and the Asset and
                  Liability Management Committee. Holding a Bachelor's degree in
                  Psychology from the University Of Cape Coast and a Diploma in
                  Human Resource Management from the Institute of Commercial
                  Management, UK, she is certified by the Chartered Institute of
                  Human Resource Management (CIHRM), Ghana. Fluent in English,
                  Ga, and Akan languages, Elizabeth enjoys music, reading, and
                  dancing outside of work, reflecting her vibrant personality.
                  &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>

              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
      </div>

      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.team-style-one sec-pad -->

  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "MgtProfileContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      currentSelected: 0,
    };
  },
  methods: {
    setSelected(id) {
      this.currentSelected = id;
    },
    removeSelected() {
      this.currentSelected = 0;
    },
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
.text-box p {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
