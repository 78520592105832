<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="pressrelease.jpg"/>
  
  <!-- /.count-down-style-one-wrapper -->
  <PressReleaseContent />

  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PressReleaseContent from "@/components/PressReleaseContent.vue";

export default {
  name: "Rates",
  components: {
    InnerHeaderContent,
    Header,    
    Footer,
    PressReleaseContent,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Rates");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
