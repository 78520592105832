<template>
  <header class="header header-home-one">
    <nav class="navbar navbar-default header-navigation stricky">
      <div class="thm-container clearfix">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed bitmex-icon-menu"
            data-toggle="collapse"
            data-target=".main-navigation"
            aria-expanded="false"
          ></button>

          <a class="navbar-brand" :href="'/'">
            <img src="img/logo.png" alt="Awesome Image" />
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div
          class="collapse navbar-collapse main-navigation mainmenu"
          id="main-nav-bar"
        >
          <ul class="nav navbar-nav navigation-box">
            <li v-bind:class="{ current: getCurrentPage == 'Home' }">
              <a :href="`/`">Home</a>
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'About' }">
              <a :href="`mission-vision-values`">About Us</a>
              <ul class="sub-menu">
                <li><a :href="`board`">Board Of Directors</a></li>
                <li><a :href="`mgt-profile`">Management Team</a></li>
                <li>
                  <a :href="'mission-vision-values'"
                    >Vision, Mission &amp; Values</a
                  >
                </li>              
                <li><a :href="'ceo-message'">CEO's Message</a></li>
              </ul>
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'Products' }">
              <a :href="`fixed-deposit`">Savings &amp; Investment</a>
              <ul class="sub-menu">
                <li><a :href="`fixed-deposit`">Fixed Deposit</a></li>
                <li><a :href="`super-saver`">Super Saver Account</a></li>
                <li><a :href="`kid-account`">Child Plus</a></li>
                <li><a :href="`savings-account`">Diamond Savings</a></li>
                <li><a :href="`our-rates`">Our Rates</a></li>
                <li><a :href="`rate-calculator`">Rate Calculator</a></li>
                <!--
                <li>
                  <a href="#">Loans</a>
                  <ul class="sub-menu">
                    <li><a :href="`salary-advance`">Salary Advance</a></li>
                  </ul>
                </li>
                <li>
                  <a href="business-start-up">Advisory</a>
                </li>
                -->
              </ul>
              <!-- /.sub-menu -->
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'Products' }">
              <a :href="`quick-pay`" >Loans</a>
              <ul class="sub-menu">
                <li>
                  <a :href="`lpo-contract`">Contract &amp; LPO Financing</a>
                </li>
                <li>
                  <a :href="`invoice-cert`"
                    >Certificate &amp; Invoice Discounting</a
                  >
                </li>
                <li><a :href="`quick-pay`">Payroll Loans (Quick-Pay)</a></li>
                <li><a :href="`loan-calculator`">Loan Calculator</a></li>
                <!--
                <li>
                  <a href="#">Loans</a>
                  <ul class="sub-menu">
                    <li><a :href="`salary-advance`">Salary Advance</a></li>
                  </ul>
                </li>
                <li>
                  <a href="business-start-up">Advisory</a>
                </li>
                -->
              </ul>
              <!-- /.sub-menu -->
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'Products' }">
              <a :href="`gallery`">Media</a>
              <ul class="sub-menu">
                <li>
                  <a :href="`gallery`">Gallery</a>
                </li>
                <li>
                  <a :href="`press-release`">Press Releases</a>
                </li>
                <!--
                <li><a :href="`annual-report`">Annual Reports</a></li>
                -->
                <li><a :href="`form-download`">Forms</a></li>
                <!--
                <li>
                  <a href="#">Loans</a>
                  <ul class="sub-menu">
                    <li><a :href="`salary-advance`">Salary Advance</a></li>
                  </ul>
                </li>
                <li>
                  <a href="business-start-up">Advisory</a>
                </li>
                -->
              </ul>
              <!-- /.sub-menu -->
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'Contact' }">
              <a :href="`news`">Stories</a>
            </li>
            <li v-bind:class="{ current: getCurrentPage == 'Products' }">
              <a :href="`working-at-dci`">Career</a>
              <ul class="sub-menu">
                <li>
                  <a :href="`working-at-dci`">Working at DCI</a>
                </li>
                <li>
                  <a :href="`internship-national`"
                    >Internship &amp; National Service</a
                  >
                </li>
                <li>
                  <a :href="`graduate-specialist`"
                    >Graduates &amp; Specialists</a
                  >
                </li>
                <li><a :href="`reward-honours`">Rewards &amp; Honours</a></li>
                <li><a :href="`job-opportunities`">Job Opening</a></li>
              </ul>
              <!-- /.sub-menu -->
            </li>
            <li v-bind:class="{ current: getCurrentPage == 'CorporateInformation' }">
              <a :href="`financial-information`">Corporate Information</a>
              <ul class="sub-menu">
                <li>
                  <a :href="`financial-information`">Financial Information</a>
                </li>
                <li>
                  <a :href="`business-certificate`"
                    >Business Certificate</a
                  >
                </li>

              </ul>
              <!-- /.sub-menu -->
            </li>

            <li v-bind:class="{ current: getCurrentPage == 'Contact' }">
              <a :href="`feedback`">Contact</a>
            </li>
            <li v-bind:class="{ current: getCurrentPage == 'Contact' }">
              <a :href="`open-account`">Open Account</a>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
        <!-- /.right-side-box -->
      </div>
      <!-- /.container -->
    </nav>
  </header>
  <!-- /.header -->
</template>

<script>
export default {
  name: "Header",
  props: {},
  computed: {
    getCurrentPage() {
      return this.$store.getters.getCurrentPage;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
