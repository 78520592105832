<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />

  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Rewards &amp; Honours</h3>
      <p>
        At DCI Microfinance Limited, we reward excellence and hard work. We have
        a system in which all people management decisions such as promotion,
        reward, development, placement, etc., are based on high performance that
        is aligned to business objectives.
      </p>
      <p>
        We appreciate the importance of and believe in recognizing individual
        quality and rewarding good performance. We believe that the skills,
        effort and dedicated work of our people should be celebrated and
        rewarded. It is important to note that our reward and benefits vary
        depending on your area of work and the responsibilities you cover within
        the organization.
      </p>
      <p>
        Our employee benefits include salary, discretionary annual bonus,
        performance incentives, learning and development for your personal and
        professional growth, flexible working hours, annual leave, health care
        management and pension benefits amongst others.
      </p>
      <!-- /.list-items -->
    </div>
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import YouTube from "vue3-youtube";

export default {
  name: "RewardHonours",
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    InnerHeaderContent,
    Header,
    YouTube,

    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Working At DCI";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
