import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import LazyTube from "vue-lazytube";
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'

createApp(App).use(VueUniversalModal, {
    teleportTarget: '#my-modals',
    modalComponent: 'MyModal',
}).use(router).use(LazyTube).use(store).mount('#app')
