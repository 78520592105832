<template>
  <section class="btc-calculator quick sec-pad">
    <div class="thm-container" v-show="fixedRate != 0">
      <div class="sec-title text-center black">
        <span>Our Quick Pay</span>
        <h3>Calculator</h3>
        <p></p>
      </div>
      <!-- /.sec-title -->
      <form action="#" class="btc-calc-form row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Enter Loan Amount (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                v-model="principal"
                placeholder="Enter principal amount (GHS)"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">Select Loan Period</div>
            <div class="col-md-12 col-sm-12">
              <select class="selectpicker" v-model="period">
                <option value="24">24 months</option>
                <option value="36">36 months</option>
                <option value="48">48 months</option>
                <option value="60">60 months</option>
                <option value="72">72 months</option>
                <option value="84">84 months</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Monthly Repayments (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                disabled
                placeholder="Expected Amount At Maturity"
                v-model="returns"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
      </form>
      <!-- /.btc-calc-form -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.btc-calculator -->
</template>

<script>
export default {
  name: "QuickPayCalculator",
  props: {
    msg: String,
  },
  data: function () {
    return {
      principal: "0",
      period: 24,
      fixedRate: 0,
      fixedRates: [],
    };
  },
  computed: {
    getFixedRate() {
      const period = this.period;
      return this.fixedRates.length == 0
        ? {rate: 0}
        : this.fixedRates.filter(function (e) {
            return e.period == period;
          })[0];
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    returns() {
      let temp = this.principal;
      var rate = 0;

      var principal = parseFloat(temp.replace(",", ""));
      if(typeof this.getFixedRate != 'undefined')
      rate = parseFloat(this.getFixedRate.rate);
      if (rate == 0) return 0.0;

      console.log(rate);
      console.log(principal);
      if (isNaN(rate) || isNaN(principal)) return 0.0;

      if (principal == 0 || principal < 1 || rate == 0) return 0.0;

      var rnd = function (n) {
        return Math.round(n * 100) / 100; // round to 2 digits
      };
      var interest = Number(rate);
      var months = Number(this.period);
      var i = interest / 100.0;
      var mainInner = (1 + i)
      const mainPower = Math.pow(mainInner, months);
      console.log('mainPower = ', mainPower);
      //lets calculate top
      const mainTop = mainPower * i;
      console.log('mainTop = ', mainTop);
      //lets calculate bottom
      const mainBottom = mainPower - 1;
      console.log('mainBottom = ', mainBottom);
      //lets divide 
      const mainDivide = mainTop / mainBottom;
      //lets multiply
      var payment = rnd(principal * mainDivide);
      const maturity = payment;
      if (typeof maturity === "number") return maturity.toFixed(2);
      return 0.0;
    },
  },

  created() {
    this.$store.dispatch("loadCalcRate", "quick").then((data) => {
      this.fixedRate = data.rate;
      this.period = 24;
      this.fixedRates.push({ rate: data.rate, period: 24 });
    });

    this.$store.dispatch("loadCalcRate", "quick2").then((data) => {
      this.fixedRates.push({ rate: data.rate, period: 36 });
    });
    this.$store.dispatch("loadCalcRate", "quick3").then((data) => {
      this.fixedRates.push({ rate: data.rate, period: 48 });
    });
    this.$store.dispatch("loadCalcRate", "quick4").then((data) => {
      this.fixedRates.push({ rate: data.rate, period: 60 });
    });
    this.$store.dispatch("loadCalcRate", "quick5").then((data) => {
      this.fixedRates.push({ rate: data.rate, period: 72 });
    });
    this.$store.dispatch("loadCalcRate", "quick6").then((data) => {
      this.fixedRates.push({ rate: data.rate, period: 84 });
    });
  },
};
</script>
<style scoped>
</style>
