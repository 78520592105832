<template>
  <section class="team-style-one sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center">
        <span>Meet our team</span>
        <h3>Board of Directors</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/01.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Professor Boasiako Omane-Antwi</h3>
              <span>Board Chairman</span>
              <div class="text-justify">
                <p v-show="currentSelected != 1">
                  Professor Boasiako Omane-Antwi, affectionately called Kwame is
                  a Fellow of the Association of Chartered Certified Accountants
                  (ACCA, UK ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(1)"
                  ></i>
                </p>
                <p v-show="currentSelected == 1">
                  Professor Boasiako Omane-Antwi, affectionately called Kwame is
                  a Fellow of the Association of Chartered Certified Accountants
                  (ACCA, UK – Membership # 3957124). Kwame has also been a
                  member of the Institute of Chartered Accountants (Ghana) since
                  1981. He has been a university teacher for the past forty-one
                  years (41). Since 1979, he has followed a career path rich in
                  diversity, with experience in education, industry, commerce
                  and public practice in Ghana, Liberia, UK and USA. He was
                  trained by PKF (Pannell Kerr Forster), Chartered Accountants
                  International. He trained as a university teacher in the one
                  year International Teacher’s Programme (ITP) of Harvard
                  University, USA.
                </p>
                <p v-show="currentSelected == 1">
                  Kwame holds a PhD in Accounting, Professional Doctorate in
                  Taxation, MA in Accounting Education, and MBA in Information
                  Technology among others. He holds professional membership of
                  various professional bodies including Chartered College of
                  Teaching (UK), Institute of Directors (both UK and Ghana),
                  Association of Certified Fraud Examiners (USA), Institute of
                  Management Information System (UK), Information Systems Audit
                  and Control Association (ISACA) USA, to mention a few.
                </p>
                <p v-show="currentSelected == 1">
                  He has attended a number of executive education programmes
                  including Corporate Financial Management (Harvard Business
                  School, 1993); Leadership, Decision and Negotiations (Yale
                  School of Management, Yale University, 1995), Management of
                  Higher Education Institutions (Hebrew University of Jerusalem,
                  Israel, 2007).
                </p>
                <p v-show="currentSelected == 1">
                  He is an alumnus of Oxford Business School, Green Templeton
                  College, University of Oxford (Oxonian Number 8-10273254), UK.
                  He has eight (8) Text books, forty-three (43) refereed journal
                  articles, one hundred and seven (107) conference and
                  consultancy papers and other publications to his credit.
                </p>
                <p v-show="currentSelected == 1">
                  He has lectured in Accounting, Finance, Auditing and
                  Management Science in the following institutions: Kwame
                  Nkrumah University of Science and Technology, Kumasi,
                  University of Cape Coast, University of Education, Winneba –
                  Kumasi Campus, Kumasi Technical University and University of
                  Liberia. He was the Past-President of the following
                  Professional Bodies in Ghana: <br />(i) The Institute of
                  Chartered Accountants, Ghana (ICAG) – (2014 – 2016).
                  <br />(ii) The Institute of Directors, Ghana (2006 – 2008).
                  <br />(iii) Association of Chartered Certified Accountants
                  (ACCA) Ghana Branch 2003 – 2004.
                </p>
                <p v-show="currentSelected == 1">
                  He is the Patron and Founding Member of the following bodies
                  in Ghana: <br />• Chartered Institute of Credit Management.
                  <br />• Association of Certified Fraud Examiners of USA, Ghana
                  Branch.<br />
                  He chairs the Board of Pentecost Hospital, Graphic
                  Communications Group Ltd. (immediate past Chairman), Diamond
                  Capital Microfinance Co. Ltd., KAAF University College (KUC)
                  and Accra Business School; as well as Board Membership of
                  Millennium Insurance Company Ltd., Abii National Savings and
                  Loans Company Ltd., Jubail Specialist Hospital, National
                  Accreditation Board and Perez University College. Kwame
                  represents ICAG on the Internal Audit Agency Board of Ghana
                  (2014 – 2016) and was nominated as a member of the University
                  of Cape Coast Vice Chancellor’s Club. He is currently the
                  Chairman of University of Cape Coast Audit Committee.
                </p>
                <p v-show="currentSelected == 1">
                  Prof. Omane-Antwi has consulted for international
                  organisations on alternative livelihood projects including the
                  World Bank, DFID of UK, Golden Star Resources Ltd. (USA Mining
                  Company in Ghana) and FMO of Netherlands.
                </p>
                <p v-show="currentSelected == 1">
                  He is currently a retired university teacher and a consultant
                  to Ghana Tertiary Education Commission ( GTEC).
                </p>
                <p v-show="currentSelected == 1">
                  Kwame is a Christian by faith and a Church Officer of the
                  Church of Pentecost in Accra. A proud old student of Mpraeso
                  Senior High School (MPASS), Mpraeso – Kwahu, Eastern Region of
                  Ghana.
                </p>
                <p v-show="currentSelected == 1">
                  He is also a farmer and on 4th December, 2015 won the 31st
                  National Farmers’ Day Metro/Municipal/District Award as the
                  best Agro Processor of Noyem in the Birim North District in
                  the Eastern Region of Ghana. He was a winner of Gold Award in
                  Pineapple Export 2001 – The 13th Export Promotion Award of
                  Ghana.
                </p>
                <p v-show="currentSelected == 1">
                  Kwame is happily married and has three (3) adult children.
                  &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/02.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Selasie Woanyah</h3>
              <span>Managing Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 2">
                  Selasie Woanyah, co-founder and Managing Director of DCI
                  Microfinance since its establishment in 2012, brings over 15
                  years of banking experience ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(2)"
                  ></i>
                </p>
                <p v-show="currentSelected == 2">
                  Selasie Woanyah, co-founder and Managing Director of DCI
                  Microfinance since its establishment in 2012, brings over 15
                  years of banking experience to the organization. With a
                  background in banking roles at Zenith Bank Ghana Ltd,
                  including Relationship Manager, Branch Manager, and leadership
                  positions in specialized units, he has demonstrated expertise
                  in diverse sectors. Before his banking career, Selasie gained
                  valuable experience in construction with China State Hualong
                  Construction and The Consortium consultancy firm. He holds a
                  degree in Building Technology from KNUST and is a qualified
                  lawyer, having earned LLB and LLM degrees from the University
                  of Ghana. His multifaceted skill set and extensive industry
                  knowledge contribute significantly to DCI Microfinance's
                  success and strategic direction. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/03.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Mathew Lawer Teye</h3>
              <span>Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 3">
                  Mathew Lawer Teye, co-founder and Executive Director
                  overseeing Marketing and Business Development at DCI
                  Microfinance, brings over 17 years of ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(3)"
                  ></i>
                </p>
                <p v-show="currentSelected == 3">
                  Mathew Lawer Teye, co-founder and Executive Director
                  overseeing Marketing and Business Development at DCI
                  Microfinance, brings over 17 years of banking and finance
                  experience from England and Ghana. His tenure at Zenith Bank
                  Ghana Ltd, notably in the Construction and Real Estate
                  financing unit, equipped him with expertise as a Relationship
                  Manager and Unit Head. Prior to Zenith Bank, he served at the
                  Royal Bank of Scotland (RBS) in Corporate Banking and gained
                  valuable experience in facilities management at Atkins,
                  Faithful and Gould in England. Mr. Teye holds an MSc in
                  Finance from the University of Bedfordshire and a BSc in
                  Administration from the University of Ghana. His extensive
                  background in both banking and real estate sectors, coupled
                  with his academic qualifications, positions him as a key
                  driver of DCI Microfinance's marketing strategies and business
                  development initiatives. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/04.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Mr Samuel Yallow</h3>
              <span>Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 4">
                  Samuel Yallow, a seasoned banker with over 15 years in the
                  financial services sector, co-founded DCI Microfinance after a
                  successful tenure at Zenith Bank ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(4)"
                  ></i>
                </p>
                <p v-show="currentSelected == 4">
                  Samuel Yallow, a seasoned banker with over 15 years in the
                  financial services sector, co-founded DCI Microfinance after a
                  successful tenure at Zenith Bank. During his six-year tenure,
                  he played integral roles in the Treasury Operations and
                  Relationship Management units, where he contributed
                  significantly to operational policy enhancements and customer
                  satisfaction initiatives. His diverse experience provided a
                  holistic understanding of banking operations and emphasized
                  the synergy between operational and marketing functions for
                  optimal customer service. Mr. Yallow holds a Bachelor’s degree
                  in Business Administration with a Finance specialization, an
                  MBA in Finance from the University of Ghana Business School
                  and an MA in Corporate and Commercial Law also from the
                  University of Ghana. His expertise and dedication make him a
                  valuable asset in driving DCI Microfinance's growth and
                  ensuring client-centric operations. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
      </div>
      <div class="row">
        <!-- /.col-md-4 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/05.JPG" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Frank Achampong</h3>
              <span>Non Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 5">
                  Mr. Frank Achampong is one of Ghana's captains of industry and
                  brings over thirty-six (36) years’ experience in business to
                  the board of DCI Microfinance ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(5)"
                  ></i>
                </p>
                <p v-show="currentSelected == 5">
                  Mr. Frank Achampong is one of Ghana's captains of industry and
                  brings over thirty-six (36) years’ experience in business to
                  the board of DCI Microfinance. He single-handedly started and
                  built Facol Roads Ltd from a road-line marking company into
                  one of Ghana's most successful indigenous civil engineering
                  and construction companies. He is also the Chairman of Locaf
                  industries, a bitumen products processing company. Mr
                  Achampong served Ghana Full Gospel Businessmen at the highest
                  level in Ghana for several years and now serves as one of 70
                  worldwide international Directors. Mr. Achampong is a graduate
                  of South West London College, London and Park Lane College,
                  Leeds. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img
                src="img/Dr Michael Boadi Nyamekye.jpg"
                alt="Awesome Image"
              />
            </div>
            <div class="text-box text-center">
              <h3>Prophet Michael Nyamekye</h3>
              <span>Non Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 6">
                  Prophet Michael Nyamekye is a consummate marketing expert,
                  preacher, teacher and Christian leader. He is a graduate ...
                  &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(6)"
                  ></i>
                </p>
                <p v-show="currentSelected == 6">
                  Prophet Michael Nyamekye is a consummate marketing expert,
                  preacher, teacher and Christian leader. He is a graduate of
                  the Kumasi Polytechnic where he obtained an HND in Accounting,
                  University of Cape Coast where he obtained a BMS, University
                  of Ghana Business School where he obtained an MPHIL in
                  Marketing and obtained an MBA from the Ghana Institute of
                  Management and Public Administration. He is currently a
                  Marketing PHD candidate at the University of Ghana Business
                  School. Prophet Nyamekye is the President of the Maker's House
                  Chapel International. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
            </div>
          </div>
        </div>
    /.col-md-4 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/Mr Akim Odio Tijani.jpg" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>AKIM ADIO TIJANI</h3>
              <span>Non Executive Director</span>
              <div class="text-justify">
                <p v-show="currentSelected != 7">
                  Mr. Akim Adio Tijani is currently the Plant Manager, Akosombo
                  Generating Station, Volta River Authority (the “VRA”) the
                  leading power generating company in Ghana ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(7)"
                  ></i>
                </p>
                <p v-show="currentSelected == 7">
                  Mr. Akim Adio Tijani is currently the Plant Manager, Akosombo
                  Generating Station, Volta River Authority (the “VRA”) the
                  leading power generating company in Ghana. Mr. Tijani rose
                  through the ranks to his current position from the position of
                  Graduate Management Trainee when he joined the VRA in 1999.
                  Prior to joining the VRA, Akim worked with the former Ghana
                  Telecom now Vodafone as a Network Implementation Engineer from
                  1998 to 1999. Akim also had a six month stint with Sychrogrid
                  LLC in Texas USA from March to August 2013.
                </p>
                <p v-show="currentSelected == 7">
                  Akim graduated from the Kwame Nkrumah University of Science
                  and Technology in 1998 with a Bachelor of Science in
                  Electrical and Electronic Engineering. He also holds a Master
                  of Engineering in Electric Power Systems and Power Electronics
                  from the Texas A & M University College Station in Texas, USA
                  and MPA in Public Administration from the GIMPA School of
                  Public Administration and Governance. Akim has a keen interest
                  in the operation of financial markets hence his role and
                  investment in the company.
                </p>
                <p v-show="currentSelected == 7">
                  Akim is passionate about mentoring and knowledge transfer and
                  serves as an adjunct lecturer at KNUST and a guest instructor
                  at the VRA Academy. He is a fellow of Ghana Institution of
                  Engineering (GhIE) and an active member of the institute of
                  Electrical and Electronics Engineers (IEEE). &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-team-style-one hvr-float-shadow">
            <div class="img-box">
              <img src="img/austin.jpg" alt="Awesome Image" />
            </div>
            <!-- /.img-box -->
            <div class="text-box text-center">
              <h3>Kwesi Austin</h3>
              <span>BOARD SECRETARY</span>
              <div class="text-justify">
                <p v-show="currentSelected != 8">
                  Kwesi Austin, a legal practitioner, serves as the Company
                  Secretary and Head of the Legal and Compliance Department at
                  DCI Microfinance Ltd ... &nbsp;<i
                    class="fa fa-plus hover-curser"
                    @click="setSelected(8)"
                  ></i>
                </p>
                <p v-show="currentSelected == 8">
                  Kwesi Austin, a legal practitioner, serves as the Company
                  Secretary and Head of the Legal and Compliance Department at
                  DCI Microfinance Ltd. Additionally, he is an Associate at
                  Amartefio & Co, Ayawaso Chambers, where he has garnered
                  extensive practical experience since 1999, specializing in
                  commercial, company law, and litigation. He holds a Bachelor
                  of Arts Degree in Economics from the University of Ghana and
                  an LLB from the University of London. Mr. Austin's exceptional
                  academic performance earned him the prestigious John Mensah
                  Sarbah Award as the overall best graduating lawyer of the
                  Ghana School of Law in 2012, following his admission to the
                  Ghana Bar in the same year. His multifaceted expertise and
                  dedication to legal excellence make him an invaluable asset to
                  the organization. &nbsp;<i
                    class="fa fa-minus hover-curser"
                    @click="removeSelected()"
                  ></i>
                </p>
              </div>
              <!-- /.social -->
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-team-style-one -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.team-style-one sec-pad -->

  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "BoardContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      currentSelected: 0,
    };
  },
  methods: {
    setSelected(id) {
      this.currentSelected = id;
    },
    removeSelected() {
      this.currentSelected = 0;
    },
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
.text-box p {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
