
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Fixed Deposit</h3>
      <p>
        Fixed Deposit is an investment vehicle where funds or deposits are
        locked in for a specific fixed period at fixed interest rate.
      </p>
      <h5>Features:</h5>
      <ul class="list-items">
        <li>
          Competitive interest rate.
        </li>
        <li>Guaranteed liquidity of investment.</li>
        <li>Tenor ranges from 91 days - 365 days.</li>
      </ul>

      <!-- /.list-items -->
      <button class="thm-btn yellow-bg" v-on:click="openFixedDeposit()">
        Open An Account
      </button>
    </div>
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
    <!-- /.thm-container -->
  </section>
  <InvestmentCalculator></InvestmentCalculator>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";
import InvestmentCalculator from "@/components/InvestmentCalculator.vue";
export default {
  name: "InvestmentContent",
  data: function () {
    return {
      ytVideo: "",
    };
  },
  props: {
    msg: String,
  },
  components: { YouTube, InvestmentCalculator },
  methods: {
    openFixedDeposit() {
      this.$store.commit("setCurrentSubPage", "savings");
      this.$router.replace("open-account");
    },
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Fixed Deposit";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
