<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />
  <!-- /.count-down-style-one-wrapper -->

  <section class="contact-page-wrapper sec-pad">
    <div class="thm-container">
      <h3>Become An Agent</h3>
      <div class="row">
        <div class="col-md-12">
          <form
            action="#"
            @submit.prevent="submitApplication"
            class="contact-form row"
          >
            <div class="col-md-12">
              <input
                type="text"
                placeholder="Full Name"
                name="name"
                v-model="name"
                required
              />
            </div>
            <div class="col-md-6">
              <input
                type="date"
                placeholder="Date Of Birth"
                name="dateOfBirth"
                v-model="dateOfBirth"
                required
              />
            </div>
            <div class="col-md-6">
              <input
                type="number"
                placeholder="Age"
                name="name"
                v-model="age"
                required
              />
            </div>
            <div class="col-md-12">
              <input
                type="text"
                placeholder="Place Of Residence"
                name="name"
                v-model="placeOfResidence"
                required
              />
            </div>
            <div class="col-md-6">
              <select
                class="form-controller"
                v-model="levelEducation"
                style="width: 100%; height: 53px"
                id="education"
              >
                <option>Level Of Education</option>
                <option value="BECE">BECE</option>
                <option value="WASSCE">WASSCE</option>
                <option value="DEGREE">DEGREE</option>
              </select>
            </div>

            <div class="col-md-6">
              <input
                type="text"
                placeholder="Languages Spoken"
                name="name"
                v-model="languagesSpoken"
                required
              />
            </div>

            <div class="col-md-6">
              <input
                type="tel"
                placeholder="Telephone Number"
                name="name"
                v-model="phone"
                required
              />
            </div>

            <div class="col-md-6">
              <input
                type="email"
                placeholder="Email Address"
                name="name"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-12 alert alert-warning" v-if="hasErrors">
              {{ errors }}
            </div>
            <div class="col-md-12" style="padding-top: 20px">
              <button
                class="thm-btn yellow-bg"
                type="submit"
                :disabled="loading"
              >
                Submit Request
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                <i class="fa fa-check-circle-o" v-if="submitted"></i>
              </button>
            </div>
            <!-- /.col-md-12 -->
          </form>
          <!-- /.contact-form -->
        </div>
        <!-- /.col-md-8 -->

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.contact-page-wrapper -->
  <!-- /.contact-page-wrapper -->

  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "AgentRegister",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
  },
  computed: {
    checkPassportFile() {
      return "Selected File : " + this.passportFile;
    },
    checkCVFile() {
      return "Selected File : " + this.cV;
    },
    checkCoverLetterFile() {
      return "Selected File : " + this.coverLetter;
    },
    checkCanSubmit() {
      if (
        this.name == "" ||
        this.dateOfBirth == "" ||
        this.age == "" ||
        this.placeOfResidence == "" ||
        this.levelEducation == "" ||
        this.languagesSpoken == "" ||
        this.phone == "" ||
        this.email == ""
      ) {
        return false;
      }
      return true;
    },
  },
  data: function () {
    return {
      name: "",
      dateOfBirth: "",
      age: "",
      placeOfResidence: "",
      levelEducation: "BECE",
      languagesSpoken: "",
      phone: "",
      email: "",
      hasErrors: false,
      errors: "",
      loading: false,
      formType: "Become An Agent",
      submitted: false,
      hasPassportFile: false,
      hasCV: false,
      hasCoverLetter: false,
      passportFile: "",
      cV: "",
      coverLetter: "",
      passportUrl: "",
      cvUrl: "",
      coverLetterUrl: "",
      filesUploaded: [],
    };
    //LateMonth
  },
  methods: {
    submitApplication() {
      if (!this.checkCanSubmit) {
        console.log("here");
        this.hasErrors = true;
        this.errors = "Kindly complete all fields";
        return;
      } else {
        console.log("there");

        this.hasErrors = false;
      }
      const safeEnvoy = axios.create({
        baseURL: `https://semrapi.onovait.com/`,
      });
      var message =
        "Request to be an agent, agent details <br/> Name : " +
        this.name +
        " <br/> Date Of Birth : " +
        this.dateOfBirth +
        " <br/> Age: " +
        this.age +
        " <br/> Place Of Residence : " +
        this.placeOfResidence +
        " <br/> Level Of Education : " +
        this.levelEducation +
        " <br/> Languages Spoken : " +
        this.language +
        "<br/> Telephone Number : " +
        this.phone +
        "<br/> Email : " +
        this.email +
        "<br/>";
      this.loading = true;
      try {
        safeEnvoy.post("envoy", {
          _email: "recruitment@diamondcapital.com.ghh",
          _subject: "New Agent Registration",
          _headless: true,
          name: this.name,
          message: message,
        }).then((e) => {
            console.log(e);
            this.submitted = true;
            this.loading = false;
          });
      } catch (error) {
        console.log({ error });
      }
    },
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
<style scoped>
.btn-file {
  margin-top: 10px;
}
.changed-input {
  margin-left: 0px;
}
</style>