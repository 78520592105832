
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>From MDs Desk</h3>
      <p>
        Welcome to DCI Microfinance. Our dream is to setup a financial service company that understands the needs of the SME market in Ghana and across the West African Sub-region
      </p>
     
      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "CeoMessageContent",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
</style>
