<template>
  <!-- /.preloader -->
  <Header />
  <Carousel />
  <Count />
  <!-- /.count-down-style-one-wrapper -->
  <HomeContent />
  <WhyChoose />

  <!-- /.feature-style-two -->

  <LatestNews />
  <!-- /.sec-pad blog-style-one -->
  <!--
  <RatesContent />
  -->
  <QuickPayCalculator />
  <RatesFtContent/>
  <InvestmentCalculator />

  <!-- /.fun-fact-style-one -->
  <Testimonial />
  <Footer />
</template>

<script>
// @ is an alias to /src

import Carousel from "@/components/Carousel.vue";
import Header from "@/components/Header.vue";
import HomeContent from "@/components/HomeContent.vue";
import InvestmentCalculator from "@/components/InvestmentCalculator.vue";
import QuickPayCalculator from "@/components/QuickPayCalculator.vue";
import Footer from "@/components/Footer.vue";
import Testimonial from "@/components/Testimonial.vue";
import LatestNews from "@/components/LatestNews.vue";
import WhyChoose from "@/components/WhyChoose.vue";
import RatesContent from "@/components/RatesContent.vue";
import Count from "@/components/Count.vue";
import RatesFtContent from "@/components/RatesFtContent.vue";

export default {
  name: "Home",

  components: {
    Carousel,
    Header,
    Count,
    HomeContent,
    InvestmentCalculator,
    Footer,
    LatestNews,
    Testimonial,
    WhyChoose,
    RatesContent,
    QuickPayCalculator,
    RatesFtContent
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Home");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
