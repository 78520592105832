<template>
  <section class="inner-banner gray-bg text-center" :style="getBackImage">
    <div class="thm-container">
     
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.inner-banner -->
</template>

<script>
export default {
  name: "InnerHeaderContent",
  props: {
    msg: String,
    bgimage: String,
  },
  computed: {
    getBackImage(){
      return {
        'background-image': `url(/img/${this.bgimage})`
      }
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>

</style>
