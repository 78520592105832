<template>
  <section class="btc-calculator sec-pad">
    <div class="thm-container" v-show="fixedDepositRates.length > 0">
      <div class="sec-title text-center black">
        <span>Our Investment</span>
        <h3>Calculator</h3>
        <p></p>
      </div>
      <!-- /.sec-title -->
      <form action="#" class="btc-calc-form row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Enter Inv. Amount (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                v-model="principal"
                placeholder="Enter principal amount (GHS)"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Select Investment Period
            </div>
            <div class="col-md-12 col-sm-12">
              <select class="selectpicker" v-model="period">
                <option value="30">30 Day ( FT )</option>
                <option value="60">60 Day ( FT )</option>
                <option value="91">91 Day ( FT )</option>
                <option value="182">182 Day ( FT )</option>
                <option value="365">365 Day ( FT )</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Interest At Maturity (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                disabled
                placeholder="Interest Amount At Maturity"
                v-model="returns.interest"
              />
            </div>
          </div>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 col-sm-12 calc-text">
              Interest Plus Principal (GHS)
            </div>
            <div class="col-md-12 col-sm-12">
              <input
                type="text"
                disabled
                placeholder="Interest Plus Principal"
                v-model="returns.total"
              />
            </div>
          </div>
        </div>

        <!-- /.col-md-3 -->
      </form>
      <!-- /.btc-calc-form -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.btc-calculator -->
</template>

<script>
export default {
  name: "InvestmentCalculator",
  props: {
    msg: String,
  },
  data: function () {
    return {
      loaded30: [],
      loaded60: [],
      loaded91: [],
      loaded182: [],
      loaded365: [],
      principal: "0",
      period: 30,
    };
  },
  computed: {
    fixedDepositRates() {
      var fixedDepositRates = [];
      for (var i = 0; i < this.loaded30.length; i++) {
        fixedDepositRates.push({
          tier: this.loaded30[i].tier,
          day30: this.loaded30[i].rate,
          day60: this.loaded60[i].rate,
          day91: this.loaded91[i].rate,
          day182: this.loaded182[i].rate,
          day365: this.loaded365[i].rate,
        });
      }
      return fixedDepositRates;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    returns() {
      let temp = this.principal;
      var rate = 0;

      var principal = parseFloat(temp.replace(",", ""));
      var tempRate = {};
      if (principal >= 1000 && principal < 5000)
        tempRate = this.fixedDepositRates[0];
      if (principal >= 5000 && principal < 10000)
        tempRate = this.fixedDepositRates[1];
      if (principal >= 10000 && principal < 25000)
        tempRate = this.fixedDepositRates[2];
      if (principal >= 25000 && principal < 50000)
        tempRate = this.fixedDepositRates[3];
      if (principal >= 50000 && principal < 100000)
        tempRate = this.fixedDepositRates[4];
      if (principal >= 100000 && principal < 250000)
        tempRate = this.fixedDepositRates[5];
      if (principal >= 250000 && principal < 500000)
        tempRate = this.fixedDepositRates[6];
      if (principal >= 500000 && principal < 1000000)
        tempRate = this.fixedDepositRates[7];
      if (principal >= 1000000) tempRate = this.fixedDepositRates[8];

      if (this.period == 30) rate = parseFloat(tempRate.day30);

      if (this.period == 60) rate = parseFloat(tempRate.day60);

      if (this.period == 91) rate = parseFloat(tempRate.day91);

      if (this.period == 182) rate = parseFloat(tempRate.day182);

      if (this.period == 365) rate = parseFloat(tempRate.day365);

      console.log(tempRate);
      console.log(rate);
      console.log(principal);
      if (isNaN(rate) || isNaN(principal)) return 0.0;

      if (principal == 0 || principal < 1000 || rate == 0) return 0.0;
      rate = rate / 100;
      const time = this.period / 365;
      const res = principal * rate * time;
      const maturity = res + principal;
      console.log(typeof maturity);
      //console.log(maturity)
      if (typeof maturity === "number")
        return { total: maturity.toFixed(2), interest: res.toFixed(2) };
      return { total: 0.0, interest: 0.0 };
    },
  },
  created() {
    this.$store.dispatch("loadRates", "fd").then((data) => {
      this.loadedFixeDeposit = data;
      this.loaded30 = data.filter((e) => e.days == 30);
      this.loaded60 = data.filter((e) => e.days == 60);
      this.loaded91 = data.filter((e) => e.days == 91);
      this.loaded182 = data.filter((e) => e.days == 182);
      this.loaded365 = data.filter((e) => e.days == 365);
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
