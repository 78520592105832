
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>LPO &amp; Contract Financing</h3>
      <p>
        LPO and Contract Financing is tied to your specific contracts so you can
        focus on completing your contracts without worrying about funding
        availability. From subcontractor cost to payment for materials sourced
        both locally or overseas, Contract Financing offers a wide financing
        range to meet your needs.
      </p>

      <h5>Loan Requirements</h5>
      <ul class="list-items">
        <li>Application letter</li>
        <li>
          Board Resolution (not needed when the company is a sole
          proprietorship)
        </li>
        <li>Copy of National ID cards of proposed signatures.</li>
        <li>Copy of LPO or Contract.</li>
        <li>Bank statements for the past 6 months</li>
        <li>Company profile</li>
        <li>
          Acceptable collateral (cars or houses depending on the amount been
          applied for)
        </li>
        <li>Inspection of company premises</li>
        <li>
          Any other document that may be required by DCI Microfinance Limited
        </li>
      </ul>
      <!-- /.list-items -->
      <a class="thm-btn yellow-bg" :href="'form-download'"> Download Forms </a>
    </div>
    <!-- /.thm-container -->
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";

export default {
  name: "LpoContractFinancingContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    YouTube,
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Contract and LPO Financing";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
