
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Payroll Loans (Quick-Pay)</h3>
      <p>
        DCI Microfinance Quick - Pay Loans is a lending product targeted at
        persons employed by the government and paid through the Controller and
        Accountant General’s Department. (Teachers, Doctors, Nurses, Political
        appointees and all Civil Servants)
      </p>
      <h5>Benefits of DCI Quick - Pay Loans</h5>
      <ul class="list-items">
        <li>Loan Disbursed in 36 hours</li>
        <li>
          You can receive your loan by cash, mobile money, cheque and direct
          transfer
        </li>
        <li>No upfront Payment</li>
        <li>Most competitive interest rate</li>
      </ul>
      <h5>Loan Requirements</h5>
      <ul class="list-items">
        <li>Fully Completed and Endorsed Application Form</li>
        <li>CAGD Payslip (Last 3 months Payslip)</li>
        <li>
          A valid ID: Voters’ ID, Drivers' License, Passport, SSNIT or a
          National ID
        </li>
        <li>One (1) Passport Picture</li>
      </ul>
      <!-- /.list-items -->
      <a class="thm-btn yellow-bg" :href="'form-download'"> Download Forms </a>
    </div>
    <!-- /.thm-container -->
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
  </section>
  <QuickPayCalculator></QuickPayCalculator>

  <!-- /.cta-style-three -->
</template>

<script>
import YouTube from "vue3-youtube";
import QuickPayCalculator from "@/components/QuickPayCalculator.vue";

export default {
  name: "PayRollLoanContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    YouTube,
    QuickPayCalculator,
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Payroll Loans";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
