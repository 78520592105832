<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="careersnew.jpg" />
  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Working at DCI Microfinance (Our Culture)</h3>
      <p>
        Our employees are our priority. We understand that our success thus
        depends on providing our people with an environment in which they can
        thrive and one in which we can expect their continued passion and
        commitment – and that is what we are doing.
      </p>
      <p>
        We aim to make working at DCI Microfinance Limited, an engaging and
        fulfilling experience. We want our people to be positive and
        enthusiastic about what they do, with a clear sense of purpose, and
        pride in their individual contribution to our success.
      </p>
      <!--
      <p>
        This means providing inspiring and effective leadership, open lines of
        communication, excellent learning and development opportunities, and
        competitive reward and benefits, and a culture of performance where good
        performance and success is celebrated.
      </p>
      -->

      <!-- /.list-items -->
    </div>
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import YouTube from "vue3-youtube";

export default {
  name: "WhatWeLookFor",
  data: function () {
    return {
      ytVideo: "",
    };
  },
  components: {
    InnerHeaderContent,
    Header,
    Footer,
    YouTube,
  },
  computed: {
    getYtVideo() {
      return this.ytVideo;
    },
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
  created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Working At DCI";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
