<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="careersnew.jpg"/>
  
  <!-- /.count-down-style-one-wrapper -->
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Job Opening</h3>
      <p>We shall shortly publish all available vacancies here.</p>
      <p>
        If you would like to make any enquires please send an email to
        info@diamondcapital.com.gh or call
      </p>
      <p>Tel: +233 -30 -2230010, 2230018, 2230 020, 2230 151, 2230 279</p>
      <p>
        or visit
        <br />
        C557/3 Mango Tree Avenue,
        <br />
        P.O.Box GP 167,
        <br />

        Asylum Down - Accra,
        <br />

        Ghana
      </p>

      <!-- /.list-items -->
    </div>
    <!-- /.thm-container -->
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "WhatWeLookFor",
  components: {
    InnerHeaderContent,
    Header,
    
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "About");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
