
<template>
  <section class="blog-details-page sec-pad">
    <div class="sharethis-sticky-share-buttons"></div>

    <div class="thm-container">
      <div class="row">
        <div class="col-md-9">
          <div class="single-blog-content">
            <div class="img-box">
              <img
                :src="newsSingle.cover"
                :alt="newsSingle.title"
                style="max-width: 100%;
    max-height: 100%; object-fit: contain'"
              />
            </div>
            <!-- /.img-box -->
            <h3>{{ newsSingle.title }}</h3>
            <div class="meta-info">
              <a href="#"
                ><i class="fa fa-user"></i> by {{ newsSingle.author }}</a
              >
              <a href="#"
                ><i class="fa fa-calendar"></i>
                {{ new Date(newsSingle.date).toDateString() }}</a
              >
            </div>
            <!-- /.meta-info -->
            <p style="text-align: justify" v-html="newsSingle.content"></p>

            <!-- /.post-navigation -->
          </div>
          <!-- /.single-blog-content -->
        </div>
        <!-- /.col-md-9 -->
        <div class="col-md-3">
          <div class="sidebar sidebar-right">
            <!-- /.single-sidebar -->
            <div class="single-sidebar recent-post-sidebar">
              <div class="title">
                <h3>Recent Posts</h3>
              </div>
              <!-- /.title -->
              <div class="recent-post-wrapper">
                <div
                  class="single-recent-post"
                  v-for="(newsItem, index) in newsItems"
                  :key="index"
                >
                  <div class="img-box">
                    <img :src="newsItem.cover" :alt="newsItem.title" />
                  </div>
                  <!-- /.img-box -->
                  <div class="text-box">
                    <a :href="`/news-single?newsId=${newsItem.id}`"
                      ><h3>{{ newsItem.title }}</h3></a
                    >
                  </div>
                  <!-- /.text-box -->
                </div>
              </div>
              <!-- /.recent-post-wrapper -->
            </div>
            <!-- /.single-sidebar -->
          </div>
          <!-- /.sidebar sidebar-right -->
        </div>
        <!-- /.col-md-3 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.blog-details-page -->
</template>

<script>
export default {
  name: "NewsSingleContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      newsTerm: {
        title: "",
        cover: "",
        content: "",
        writer: "",
        date: "",
      },
      allArticles: [],
    };
  },
  computed: {
    getImageBase() {
      return this.$store.getters.getImageBase;
    },
    newsId() {
      return this.$route.query.newsId;
    },
    newsItems() {
      var articles = this.allArticles.filter((article) => {
        return article.id != this.$route.query.newsId;
      });
      return articles.slice(-5);
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    newsSingle(){
      return this.newsTerm
    }
  },
  created() {
    this.$store
      .dispatch("loadArticle", this.$route.query.newsId)
      .then((data) => {
        this.newsTerm = data;
        console.log(data);
        //this.loading = false;
      });
    this.$store.dispatch("loadallArticles").then((data) => {
      this.allArticles = data.sort((a, b) =>
        Date.parse(a.date) > Date.parse(b.date) ? 1 : -1
      );
      //this.loading = false;
    });
  },
};
//
</script>
<style scoped>
.aligncontent {
  text-align: center;
}
</style>
