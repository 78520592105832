
<template>
  <section class="cta-style-three">
    <div
      class="thm-container text-center"
      v-if="getFixedDepositRates.length > 0"
    >
      <h3>Fixed Deposit Rates</h3>
      <div class="thm-container text-left">
        <div class="table-responsive exchange-table">
          <table class="table">
            <thead>
              <tr>
                <th>TIER (GHS)</th>
                <th>30-day (%)</th>
                <th>60-day (%)</th>
                <th>91-day (%)</th>
                <th>182-day (%)</th>
                <th>365-day (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(temp, index) in getFixedDepositRates" :key="index">
                <td>{{ temp.tier }}</td>
                <td>{{ temp.day30 }}</td>
                <td>{{ temp.day60 }}</td>
                <td>{{ temp.day91 }}</td>
                <td>{{ temp.day182 }}</td>
                <td>{{ temp.day365 }}</td>
              </tr>
            </tbody>
          </table>
          <!-- /.table -->
        </div>
        <!-- /.table-responsive -->
      </div>
      <!-- /.thm-container -->
    </div>

    <div class="thm-container text-center" v-if="getLoadedTb.length > 0">
      <h3>Treasury Bill Rate</h3>
      <div class="thm-container text-left">
        <div class="table-responsive exchange-table">
          <table class="table">
            <thead>
              <tr>
                <th>Period</th>
                <th>Discount Rates</th>
                <th>Interest Rates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tb, index) in getLoadedTb" :key="index">
                <td>{{ tb.period }}</td>
                <td>{{ tb.rate }}</td>
                <td>{{ tb.discount }}</td>
              </tr>
            </tbody>
          </table>
          <!-- /.table -->
        </div>
        <!-- /.table-responsive -->
      </div>
      <!-- /.thm-container -->
    </div>

    <div
      class="thm-container text-center"
      v-if="getCallAccountRates.length > 0"
    >
      <h3>Call Accounts Rates</h3>
      <!-- <p>Indicative Rates are Valid for 04-09-2017 (Date format dd-mm-yyyy)</p> -->
      <div class="thm-container text-left">
        <div class="table-responsive exchange-table">
          <table class="table">
            <thead>
              <tr>
                <th>Period</th>
                <th>Discount Rate (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(temp, index) in getCallAccountRates" :key="index">
                <td>{{ temp.period }}</td>
                <td>{{ temp.rate }}</td>
              </tr>
            </tbody>
          </table>
          <!-- /.table -->
        </div>
        <!-- /.table-responsive -->
      </div>
      <!-- /.thm-container -->
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
export default {
  name: "RatesContent",
  props: {
    msg: String,
  },
  data: function () {
    return {
      loaded30: [],
      loaded60: [],
      loaded91: [],
      loaded182: [],
      loaded365: [],
      loadedTb: [],
      loadedCa: [],
    };
  },
  computed: {
    getFixedDepositRates() {
      var fixedDepositRates = [];
      for (var i = 0; i < this.loaded30.length; i++) {
        fixedDepositRates.push({
          tier: this.loaded30[i].tier,
          day30: this.loaded30[i].rate,
          day60: this.loaded60[i].rate,
          day91: this.loaded91[i].rate,
          day182: this.loaded182[i].rate,
          day365: this.loaded365[i].rate,
        });
      }
      return fixedDepositRates;
    },
    getLoadedTb() {
      return this.loadedTb;
    },
    getCallAccountRates() {
      return this.loadedCa;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store.dispatch("loadRates", "fd").then((data) => {
      this.loaded30 = data.filter((e) => e.days == 30);
      this.loaded60 = data.filter((e) => e.days == 60);
      this.loaded91 = data.filter((e) => e.days == 91);
      this.loaded182 = data.filter((e) => e.days == 182);
      this.loaded365 = data.filter((e) => e.days == 365);
      //this.loading = false;
    });
    this.$store.dispatch("loadRates", "tb").then((data) => {
      this.loadedTb.splice(0);
      for (var i = 0; i < data.length; i++) {
        this.loadedTb.push({
          period: data[i].period,
          rate: data[i].interest_rate,
          discount: data[i].discount_rate,
        });
      }
    });
    this.$store.dispatch("loadRates", "ca").then((data) => {
      this.loadedCa.splice(0);
      for (var i = 0; i < data.length; i++) {
        this.loadedCa.push({
          period: data[i].period,
          rate: data[i].discount_rate,
        });
      }
    });
  },
};
</script>
<style scoped>
</style>
