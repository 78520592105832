<template>
  <!-- /.preloader -->
  <Header />
  <!--
  <InnerHeaderContent  bgimage="gallery.jpg"/>
  -->
  <!-- /.count-down-style-one-wrapper -->
  
  <GallerySingleContent />


  <Footer />
</template>

<script>
// @ is an alias to /src
//import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import GallerySingleContent from "@/components/GallerySingleContent.vue";

export default {
  name: "GallerySingle",
  data: function () {
    return {   
      index: null,
    };
  },
  components: {
    //InnerHeaderContent,
    Header,
    GallerySingleContent,
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Gallery");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
