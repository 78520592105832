<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="dciwebsitebanners9.jpg"/>
  <!-- /.count-down-style-one-wrapper -->

  <section class="contact-page-wrapper sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center">
        <h3>Our Branches</h3>
      </div>
      <div class="sec-title text-center">
        <h4>Head Office Asylumn Down</h4>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-6">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              C557/3 Mango Tree Avenue, P.O.Box GP 167, Asylum Down - Accra,
              Ghana
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Contact: 0501260412 / 0577669351
            </div>
          </div>
        </div>
        <div class="col-md-1 col-sm-0 col-xs-1"></div>
        <!-- /.col-md-8 -->
        <div class="col-md-4 col-sm-12 col-xs-4">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
          </div>
        </div>

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
      <hr />

      <div class="sec-title text-center">
        <h4>Abossey Okai Branch</h4>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-6">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Abossey Okai House No. B66/6, Abossey Okai High Street, Abossey
              Okai
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Contact: 0501290414 / 0501295730
            </div>
          </div>
        </div>
        <div class="col-md-1 col-sm-0 col-xs-1"></div>
        <!-- /.col-md-8 -->
        <div class="col-md-4 col-sm-12 col-xs-4">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
          </div>
        </div>

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
      <hr />
      <div class="sec-title text-center">
        <h4>Tema Branch</h4>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-6">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Tema Community 1, Baidoo’s Towers 0501265855 / 0577668734
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Contact: 0501265855 / 0577668734
            </div>
          </div>
        </div>
        <div class="col-md-1 col-sm-0 col-xs-1"></div>
        <!-- /.col-md-8 -->
        <div class="col-md-4 col-sm-12 col-xs-4">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
          </div>
        </div>

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
      <hr />

      <div class="sec-title text-center">
        <h4>Accra Central Branch</h4>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-6">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              GB Ollivant, Ollivant Arcade, Knutsford Avenue, Accra Central
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              Contact: 0501295736 / 0577669356
            </div>
          </div>
        </div>
        <div class="col-md-1 col-sm-0 col-xs-1"></div>
        <!-- /.col-md-8 -->
        <div class="col-md-4 col-sm-12 col-xs-4">
          <div class="row">
            <div class="col-12">
              <img src="img/cta-moc-1.png" alt="Awesome Image" />
            </div>
          </div>
        </div>

        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.contact-page-wrapper -->

  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Feedback",
  components: {
    InnerHeaderContent,
    Header,
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Contact");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
