<template>
  <section class="count-down-style-one-wrapper sec-pad">
    <div class="thm-container" style="margin-top: 20px; margin-bottom: 20px">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="count-down-title">
            <h1 style="color: #a62062">Who We Are</h1>
            <p>
              To us, the business of banking is based on relationships and not
              transactions. When you blend our style of banking with a keen eye
              on the future, you get a winning combination.</p><p>
              Over the period, we have grown to become a thriving financial
              institution in Ghana. While we are excited by our growth, we know
              we did not get here alone. We are guided by an experienced and
              loyal Board of Directors, a strong management team and more than
              60 dedicated employees – all committed to the core values that
              define our organization. </p><p>We are honoured to be part of the
              fabric of the financial industry which knows us for investments,
              invoice discounting, contract financing and payroll loans. Our
              approach to service is driven largely by our ability to obtain a
              clear understanding of the specific needs of Small and Medium
              Scale Enterprises and provide financial services beneficial to
              their needs.</p><p>We keep going miles beyond the extra
            </p>
            <div class="btn-box">
              <a
                href="corporate-information"
                class="pull-right cta-one-btn style-two"
              >
                <span class="text-box">
                  <span class="label-text">Read More >></span>
                </span>
              </a>
            </div>
          </div>
          <!-- /.single-feature-style-one -->
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.feature-style-one -->

  <!-- /.feature-style-one -->
</template>

<script>
export default {
  name: "HomeContent",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
</style>
