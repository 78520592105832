<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container">
      <div class="sec-title text-center">
        <h3>Form Downloads</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">      
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <!--
            <div class="img-box">
              <img src="img/report_2015.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            -->
            <!-- /.img-box -->
            <div class="text-box">
              <!-- /.meta-info -->
              <div class="exchange-btn-box exchange-btn-box-20 text-left large">
            <a href="/files/loan-application-form.pdf" class="thm-btn thm-btn-style-two" download
              ><i class="fa fa-file-text-o"></i>Download Loan Application Form</a
            >
          </div>
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
      </div>
      <!-- /.row -->
            <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <!--
            <div class="img-box">
              <img src="img/report_2016.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            -->
            <!-- /.img-box -->
            <div class="text-box">
              <!-- /.meta-info -->
              <div class="exchange-btn-box exchange-btn-box-20 text-left large">
            <a href="/files/daimond-account-opening.pdf" class="thm-btn thm-btn-style-two" download
              ><i class="fa fa-file-text-o"></i>Download Account Opening Form</a
            >
          </div>
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
      
          <!-- /.single-blog-style-one -->
        </div>        

      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>

</template>

<script>
export default {
  name: "FormDownloadContent",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
  created() {
    this.$store.dispatch("loadForm").then((data) => {
      this.newsList = data;
      //this.loading = false;
    });
  },
};
</script>
<style scoped>
</style>
