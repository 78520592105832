<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent bgimage="Loansnewnew.jpg"/>
  
  <!-- /.count-down-style-one-wrapper -->
  <LpoContractFinancingContent />

  <Footer />
</template>

<script>
// @ is an alias to /src

import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import LpoContractFinancingContent from "@/components/LpoContractFinancingContent.vue";

export default {
  name: "Loans",
  components: {
    InnerHeaderContent,
    Header,
    
    Footer,
    LpoContractFinancingContent,
  },
  mounted() {
             window.scrollTo(0,0);
    this.$store.commit("setCurrentPage", "");
    this.$store.commit("setCurrentSubPage", "SubLoans");
  },
};
</script>
