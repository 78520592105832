<template>
  <section class="feature-style-two sec-pad gray-bg gray-bg-top">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12">
          <div class="feature-style-two-title">
            <h3 style="font-size: 46px">Products &amp; Services</h3>
            <!--
              <a href="savings-account" class="thm-btn yellow-bg-hvr">Learn More</a>
              -->
          </div>
          <!-- /.feature-style-two-title -->
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div class="feature-style-two-content">
            <div class="row feature-style-two-content-row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box savings-icon dci-icon"></div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <router-link to="/our-rates">
                        <h3 class="choose" style="cursor: pointer">
                          Our Rates
                        </h3>
                      </router-link>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    We give you the best competitive <br />rates on the market
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box loan-icon dci-icon"></div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <router-link to="/super-saver">
                        <h3 class="choose" style="cursor: pointer">
                          Savings &amp; Investment
                        </h3>
                      </router-link>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    Save and Invest your money <br />with us and enjoy the
                    <br />best interest rates
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box investment-icon dci-icon"></div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <router-link to="/quick-pay">
                        <h3 class="choose" style="cursor: pointer">Loans</h3>
                      </router-link>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    We give you the quickest loan with delight
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box advisory-icon dci-icon"></div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <router-link to="/sub-advisory">
                        <h3 class="choose" style="cursor: pointer">
                          Advisory Services
                        </h3>
                      </router-link>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    We assist you with all the financial advice you need to grow
                    your business
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.feature-style-two-content -->
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
</template>

<script>
export default {
  name: "WhyChoose",
  props: {
    msg: String,
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
  },
};
</script>
<style scoped>
.dci-icon {
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}
.advisory-icon {
  background-image: url("/img/services.png");
}
.savings-icon {
  background-image: url("/img/savings.png");
}
.investment-icon {
  background-image: url("/img/investment.png");
}
.loan-icon {
  background-image: url("/img/loan.png");
}
.feature-style-two .single-feature-style-two .top-box .text-box h3:hover {
  color: #71bfea;
}
</style>
