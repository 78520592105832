import { createStore } from 'vuex'
import axios from 'axios'
const applicationApi = "https://xwbapi.dcimicrofinance.com/api/client/";
const baseApi = "https://xwbapi.dcimicrofinance.com/";
export default createStore({
  state: {
    currentPage: 'Home',
    currentSubPage: null,
    newsList: [],
  },
  getters: {
    getImageBase() {
      return baseApi;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getCurrentSubPage(state) {
      return state.currentSubPage;
    },
    getNewsList(state) {
      return state.newsList.filter((item) => {
        return item.type === 'news';
      });
    },
    getLatestList(state) {
      var list = state.newsList.filter((item) => {
        return item.type === 'news';
      });
      return list.splice(-4);
    },
    getPressList(state) {
      return state.newsList.filter((item) => {
        return item.type === 'press';
      });
    },

    getNewsListAll(state) {
      return state.newsList
    },

  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setCurrentSubPage(state, page) {
      state.currentSubPage = page
    },
    setNewsList(state, page) {
      state.newsList = [...page]
    },
  },
  actions: {
    async loadRates({ commit }, type) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "rates/" + type)
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadCalcRate({ commit }, type) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "application-rates/" + type)
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadallArticles({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "articles")
          .then((response) => {
            console.log(response);
            commit("setNewsList", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadArticle({ commit }, articleId) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "article/" + articleId)
          .then((response) => {
            //console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadAllAlbums({ commit }) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "albums")
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadAlbum({ commit }, albumId) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "album/" + albumId)
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve({ data: response.data.data, gallery: response.data.gallery });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadForm({ commit }, formType) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "load-form/" + formType)
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadVideos({ commit }) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "load-videos")
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadTestimonials({ commit }) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "testimonial/all")
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadFinancialStatement({ commit }) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(applicationApi + "statement/all")
          .then((response) => {
            console.log(response);
            //commit("setPolicies", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

  },
  modules: {
  }
})
