
<template>
  <section class="cta-style-three">
    <div class="thm-container text-left">
      <h3>Child Plus</h3>
      <p>
        This product aims at providing every child with the best possible start
        in life financially. You can open an account with an initial minimum account balance of GHS 200.00
      </p>
      <h5>Features:</h5>
      <ul class="list-items">
        <li>Attractive interest rate.</li>
        <li>Monthly compounding interest rate.</li>
        <li>Standing Order can be set-up on the account.</li>
        <li>Guardian can use the account to secure a loan</li>
      </ul>
      <h5>Requirements for Opening a Child Plus</h5>
      <ul class="list-items">
        <li>A duly completed DCI account opening forms.</li>
        <li>
          Two passport size pictures; one passport size picture each from the
          parent or guardian and the Child.
        </li>
        <li>
          Copy of a valid national ID card of the parent and copy of a valid
          national ID card and Birth certificate of the child (where
          applicable).
        </li>
        <li>
          Copy of a valid residence permit in the case of a foreign national.
        </li>
        <li>
          Proof of residence (Utility Bill/Tenancy agreement/GPS Address).
        </li>
        <li>
          Directional sketch to the customer’s residence.
        </li>
        <li>Payment of the mandatory initial deposit of GHS200.00</li>
      </ul>
      <!-- /.list-items -->
      <button class="thm-btn yellow-bg" v-on:click="openFixedDeposit()">
        Open An Account
      </button>
    </div>
    <div
      class="thm-container text-right"
      style="margin-top: 20px"
      v-if="getYtImage != ''"
    >
      <YouTube :src="getYtVideo" @ready="onReady" ref="youtube" />
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-three -->
  <ChildPlusCalculator />
</template>

<script>
import ChildPlusCalculator from "./ChildPlusCalculator.vue";
import YouTube from "vue3-youtube";

export default {
  name: "ChildPlusContent",
  props: {
    msg: String,
  },

  components: {
    ChildPlusCalculator,
    YouTube,
  },
  methods: {
    openFixedDeposit() {
      this.$store.commit("setCurrentSubPage", "child-plus");
      this.$router.replace("open-account");
    },
  },
    data: function () {
    return {
      ytVideo: "",
    };
  },
  computed: {
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
        getYtVideo() {
      return this.ytVideo;
    },
  },
    created() {
    this.$store
      .dispatch("loadVideos", this.$route.query.newsId)
      .then((data) => {
        this.ytVideo = data.filter((item) => {
          return item.page == "Super Saver";
        })[0].link;
        //this.loading = false;
      });
  },
};
</script>
<style scoped>
</style>
