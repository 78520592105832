
<template>
  <section class="sec-pad blog-style-one">
    <div class="thm-container">
      <div class="sec-title text-center">
        <h3>{{ getImageTitle }}</h3>
      </div>
      <div id="gallery-ids" class="gallery-ids">
        <img
          v-for="(image, index) in getImage"
          :key="index"
          :src="image"
          alt=""
        />
      </div>
    </div>
  </section>
  <!-- /.cta-style-three -->
</template>

<script>
//import initGallery from "@/gallery.js";
export default {
  name: "GalleryContent",
  props: {
    msg: String,
  },
  data: function () {
    var img = [];
    for (var i = 1; i <= 51; i++) {
      img.push(`img/9anniv/${i}.JPG`);
    }
    return {
      theatrical: "",
      imagesTitle: [
        {
          title: "Diamond Anniversary Thanksgiving",
        },
        {
          title: "Launch of Diamond Capital",
        },
        {
          title: "Ollivant Branch Launch",
        },
        {
          title: "Trip to Oda",
        },
        {
          title: "9th Anniversary",
        },
      ],
      images: [
        {
          sources: [
            "img/anniv1.jpg",
            "img/anniv2.jpg",
            "img/anniv3.jpg",
            "img/anniv4.jpg",
            "img/anniv5.jpg",
            "img/anniv6.jpg",
            "img/anniv7.jpg",
            "img/anniv8.jpg",
            "img/anniv9.jpg",
            "img/anniv10.jpg",
          ],
        },
        {
          sources: [
            "img/launch1.jpg",
            "img/launch2.jpg",
            "img/launch3.jpg",
            "img/launch4.jpg",
            "img/launch5.jpg",
            "img/launch6.jpg",
            "img/launch7.jpg",
            "img/launch8.jpg",
            "img/launch9.jpg",
            "img/launch10.jpg",
          ],
        },
        {
          sources: [
            "img/olivant1.jpg",
            "img/olivant2.jpg",
            "img/olivant3.jpg",
            "img/olivant4.jpg",
          ],
        },
        {
          sources: [
            "img/trip1.jpg",
            "img/trip2.jpg",
            "img/trip3.jpg",
            "img/trip4.jpg",
            "img/trip5.jpg",
            "img/trip6.jpg",
          ],
        },
        {
          sources: img,
        },
      ],
      galleryContent: [],
      galleryTitle: "",
      index: null,
    };
  },
  methods: {},
  computed: {
    getImage() {
      return this.galleryContent;
    },
    getImageTitle() {
      return this.galleryTitle;
    },
    getImageId() {
      return this.$route.query.imageId;
    },
    getCurrentSubPage() {
      return this.$store.getters.getCurrentSubPage;
    },
    anni9_source() {
      var img = [];
      for (var i = 1; i <= 51; i++) {
        img.push(`img/9anniv/${i}.JPG`);
      }
      return img;
    },
  },
  created() {
    this.$store
      .dispatch("loadAlbum", this.$route.query.imageId)
      .then(({ data, gallery }) => {
        console.log(data);
        this.galleryTitle = data.name;
        this.galleryContent.splice(0, 1);
        for (var i = 0; i < gallery.length; i++) {
          this.galleryContent.push(
            gallery[i].file
          );
        }
        //this.loading = false;
      });
  },
};
</script>



<style scoped>
html,
* {
  font-family: "Poppins";
  box-sizing: border-box;
}
body {
  background-color: #fafafa;
  line-height: 1.6;
}
.lead {
  font-size: 1.5rem;
  font-weight: 300;
}
.container {
  margin: 150px auto;
  max-width: 960px;
}
.btn {
  padding: 1.25rem;
  border: 0;
  border-radius: 3px;
  background-color: #4f46e5;
  color: #fff;
  cursor: pointer;
}
#gallery-ids {
  margin: 0;
  padding: 10px;
}
#gallery-ids {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}
#gallery-ids img {
  width: 100%;
  margin-top: 10px;
}
</style>
