<template>
  <div
    id="minimal-bootstrap-carousel"
    class="carousel slide carousel-fade slider-home-two"
    data-ride="carousel"
  >
    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
      <div
        class="item active slide-1"
        style="
          background-image: url(img/agent88.jpg);
          background-position: center center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content">
                <h3 data-animation="animated fadeInUp" style="color: black">
                  Be a payroll <br />Loan Agent Now!
                </h3>
                <p data-animation="animated fadeInDown" style="color: black">
                  You will be trained with the needed skills <br />
                  through presentations and <br />other training programs.
                </p>
                <a
                  :href="'agent-register'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Be an agent</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="item slide-2"
        style="
          background-image: url(img/banner1.jpg);
          background-position: left center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content">
                <h3 data-animation="animated fadeInUp">
                  For you we would <br />go miles beyond <br />the extra
                </h3>
                <p data-animation="animated fadeInDown">
                  Giving you the quickest loans and the <br />best interest
                  rates
                </p>
                <a
                  :href="'corporate-information'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Find out more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="item slide-3"
        style="
          background-image: url(img/banner3.jpg);
          background-position: center center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content">
                <h3 data-animation="animated fadeInUp">
                  Doing extra hours <br />just because of you
                </h3>
                <p data-animation="animated fadeInDown">
                  The extra effort to make tailor made savings <br />and loans
                  products just to suit you
                </p>
                <a
                  :href="'sub-investment'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Find out more</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="item slide-4"
        style="
          background-image: url(img/dciwebsitebannersnew1.jpg);
          background-position: left center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content" style="color: #a9a9a9">
                <h3 data-animation="animated fadeInUp" style="color: black">
                  No.1 in Investment <br />Enjoy a fun Future
                </h3>
                <p data-animation="animated fadeInDown" style="color: black">
                  Invest with Us
                </p>
                <a
                  :href="'fixed-deposit'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Find out more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="item slide-5"
        style="
          background-image: url(img/dciwebsitebannersnew2.jpg);
          background-position: center center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content">
                <h3 data-animation="animated fadeInUp">
                  We`ve got<br />your back
                </h3>
                <p data-animation="animated fadeInDown">
                  Meet that job deadline with<br />Diamond Loans
                </p>
                <a
                  :href="'lpo-contract'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Find out more</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="item slide-6"
        style="
          background-image: url(img/dciwebsitebannersnew88.jpg);
          background-position: center center;
          background-size: cover;
        "
      >
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-middle">
              <div class="content">
                <h3 data-animation="animated fadeInUp" style="color: black">
                  Payday Everyday!
                </h3>
                <p data-animation="animated fadeInDown" style="color: black">
                  With Diamond Quick Pay Loans
                </p>
                <a
                  :href="'quick-pay'"
                  class="thm-btn yellow-bg"
                  data-animation="animated fadeInDown"
                  >Find out more</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Controls -->
    <a
      class="left carousel-control"
      href="#minimal-bootstrap-carousel"
      role="button"
      data-slide="prev"
    >
      <i class="fa fa-angle-left"></i>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="right carousel-control"
      href="#minimal-bootstrap-carousel"
      role="button"
      data-slide="next"
    >
      <i class="fa fa-angle-right"></i>
      <span class="sr-only">Next</span>
    </a>

    <ul class="carousel-indicators list-inline custom-navigation">
      <li
        data-target="#minimal-bootstrap-carousel"
        data-slide-to="0"
        class="active"
      ></li>
      <!--
        -->
      <li data-target="#minimal-bootstrap-carousel" data-slide-to="1"></li>
      <li data-target="#minimal-bootstrap-carousel" data-slide-to="2"></li>
      <li data-target="#minimal-bootstrap-carousel" data-slide-to="3"></li>
      <li data-target="#minimal-bootstrap-carousel" data-slide-to="4"></li>
      <li data-target="#minimal-bootstrap-carousel" data-slide-to="5"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
