
<template>
  <div class="single-footer-widget">
    <div class="title">
      <h3>Submit a complaint</h3>
    </div>
    <div class="row">
      <div class="col-12">
        <form
          action="#"
          class="subscribe-form"
          @submit.prevent="submitComplain"
        >
          <div class="row">
            <div class="col-md-12">
              <input
                type="text"
                required
                placeholder="Email Address"
                v-on:focus="onShow"
                v-model="email"
                style="outline: 1px solid; border: none; margin-bottom: 15px"
              />
              <!--
            <button type="submit" class="fa fa-check"></button>
            --></div>
            <div class="col-md-12">
              <textarea
                name="message"
                v-model="message"
                placeholder="Type message here"
                required
                style="
                  background-color: transparent;
                  padding-top: 10px;
                  height: 100px;
                  padding-left: 20px;
                  outline: 1px solid;
                  border: none;
                  width: 100%;
                "
              ></textarea>
            </div>
            <div class="col-md-12">
              <button
                class="thm-btn yellow-bg"
                type="submit"
                style="margin-top: 40px"
              >
                Submit Complaint
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                <i class="fa fa-check-circle-o" v-if="submitted"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SubmitComplain",
  props: {
    msg: String,
  },
  data: function () {
    return {
      show: true,
      loading: false,
      submitted: false,
      email: "",
      message: "",
    };
  },
  methods: {
    submitComplain() {
      const safeEnvoy = axios.create({
        baseURL: `https://semrapi.onovait.com/`,      
      });

      this.loading = true;
      try {
        safeEnvoy
          .post("envoy", {
            _email: "enquiries@diamondcapital.com.gh",
            _subject: "New Complaint",
            _headless: true,
            email: this.email,
            message: this.message,
          })
          .then((e) => {
            console.log(e);
            this.submitted = true;
            this.loading = false;
            this.email = "";
            this.message = "";
          });
      } catch (error) {
        console.log({ error });
      }
      // info@diamondcapital.com.gh
    },
    onShow() {
      this.show = true;
    },
    onHide() {
      this.show = false;
    },
    openFixedDeposit() {
      this.$store.commit("setCurrentSubPage", "child-plus");
      this.$router.replace("open-account");
    },
  },
  computed: {},
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
