<template>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="gallery.jpg"/>
  <!-- /.count-down-style-one-wrapper -->
  
  <GalleryContent />


  <Footer />
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import GalleryContent from "@/components/GalleryContent.vue";

export default {
  name: "Gallery",
  data: function () {
    return {
      images: [
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",
        "https://dummyimage.com/800/ffffff/000000",

        "https://dummyimage.com/800/ffffff/000000",
      ],
      index: null,
    };
  },
  components: {
    InnerHeaderContent,
    Header,
    GalleryContent,
    Footer,
  },
  mounted() {
    this.$store.commit("setCurrentPage", "Gallery");
    this.$store.commit("setCurrentSubPage", "");
  },
};
</script>
